<template>
    <div>
        <TechnicalHeader
                icon="face_crux_icon"
                name="人脸关键点技术"
                title="对给定的图像进行检测，并返回图片中人脸的关键点坐标。"
                domId="FaceDetection"
        />
        <main>
            <div class="technology-area">
                <div class="technology-content">
                    <div class="describe-image-area">
                        <img src="../../assets/images/face_crux_describe.png">
                    </div>
                    <div class="technology-describe-area">
                        <p class="technology-describe-title">技术描述</p>
                        <p class="technology-describe-text">定位并返回人脸高精度关键点信息，关键点包括人脸的五官、轮廓。经过我们在安防领域长期的数据积累，经过多次数据训练，检测各种人脸姿态。关键点都有清晰明确的定义。</p>
                        <div class="technology-line"></div>
                    </div>
                </div>
            </div>

            <div id="demo-content" ref="demoContent"  class="technology-area technology-area-inverse">
                <div class="technology-content">
                    <div class="technology-example">
                        <p class="technology-example-title">功能演示</p>
                        <p class="technology-example-text">马上体验鱼住人脸关键点能力，请上传一张本地图片或提供图片URL。</p>
                        <ValidationObserver  ref="form">
                        <form class="technology-form">
                            <button @click="startShowCodeArea" class="title-button title-button-primary upload-btn" type="button">
                                <i class="fa fa-cloud-upload"></i>
                                <span data-locale="upload">立即上传</span>
                            </button>
                            <div class="upload-file-box-area">
                                <input accept="image/jpeg,image/jpg,image/png"
                                       @change="fileUpload"
                                       ref="handelUploadInput"
                                       class="upload-file-box" type="file" name="">
                            </div>
                            <div class="technology-form-group">
                                <input id="upload-file-url-box"
                                       v-model="imageUrl"
                                       data-placeholder="imageBox"
                                       class="upload-file-url-box" placeholder="图片URL" type="text" name="">
                            </div>
                            <div id="verification-code-area" class="verification-code-area"
                                 v-show="isShowCodeArea"><!-- verification-code-right -->
                                <div class="verification-code-box-area">
                                    <ValidationProvider mode="lazy" vid="verCode" name="验证码"
                                                        rules="codeRequired|verCode" v-slot="{ errors }">
                                        <input v-model="verCode" data-placeholder="verificationCodeBox" type="text"
                                               placeholder="请输入验证码">
                                        <p class="verification-code-message">{{ errors[0] }}</p>
                                    </ValidationProvider>
                                </div>
                                <!-- <p class="text-muted">输入下图中的字符</p> -->
                                <div class="verification-image-area">
                                    <img id="verification-code-image" alt="验证码" :src="codeImageSrc">
                                    <a id="refresh-verification-code" href="javascript:void(0)"
                                       @click="refreshCode"
                                    >看不清，换一张</a>
                                </div>
                                <div class="verification-btn-area">
                                    <button id="cancel-verification-code"
                                            @click="cancelShowCodeArea"
                                            class="btn btn-default" type="button">取消
                                    </button>
                                    <button @click="submitVerCode" class="btn btn-primary" type="button">确定</button>
                                </div>
                            </div>
                        </form>
                        </ValidationObserver>
                        <p class="example-prompt">您也可以选择下面图片，系统将自动进行识别。</p>

                        <div class="example-image-area">
                            <img v-for="(item,index) in images" @click="clickImage(index)"
                                 :class="imageIndex===index?'active':''" :src="item.src">
                        </div>
                    </div>
                    <div class="technology-example-result">
                        <div  v-show="!isShowResultDataArea"  class="result-image-area">
                            <div class="result-content-area">
                                <img ref="resultImage" class="result-image" :src="resultImageUrl">
                                <button @click="showResultArea(1)" type="button" class="result-data-btn">Response JSON</button>
                                <div ref="resultShowArea">
                                    <div v-for="data in faceAreaList"
                                         :style="{left:data.left+'px',top:data.top+'px'}"
                                         class="face-crux-point"></div>
                                </div>
                                <LoadIngArea v-show="loading"/>
                            </div>
                        </div>
                        <div v-show="isShowResultDataArea" class="result-data-area">
                            <pre id="result-data">{{resultData}}</pre>
                            <button  @click="showResultArea(0)" type="button" class="result-data-back-btn"><i class="fa fa-remove"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="technology-area">
                <div class="technology-content">
                    <p class="technology-scene-title">应用场景</p>
                    <ul class="technology-scene-list">
                        <li data-code="0" class="active">
                            <span>人脸照片精修</span>
                            <div class="scene-line"></div>
                        </li>
                        <li data-code="1">
                            <span>视频滤镜和贴纸</span>
                            <div class="scene-line"></div>
                        </li>
                    </ul>

                    <div class="scene-image-area">
                        <img src="../../assets/images/face_crux_scene1.png">
                        <img src="../../assets/images/face_crux_scene2.png">
                    </div>
                </div>
            </div>

            <div class="technology-area technology-buy-area">
                <div class="technology-content">
                    <p class="technology-buy-title">即刻拥有鱼住人脸关键点技术</p>
                    <p class="technology-buy-text">享受更高的性价比和技术优势</p>
                    <a href="price.html" class="buy-btn">立即购买</a>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import TechnicalHeader from '@/components/TechnicalHeader.vue';
    import LoadIngArea from '@/components/LoadIngArea';
    import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
    import {required} from 'vee-validate/dist/rules';
    import axios from 'axios';
    import {getVerCode, validCode, demoDetect, AppKey, AppSecret} from '../../assets/js/api';
    extend('codeRequired', {
        ...required,
        message: '请填写验证码！'
    });
    extend('verCode', {
        validate: value => value.length > 0 && /^(\d|\w){4}$/.test(value),
        message: '请输入正确的4位验证码'
    });
    export default {
        name: "FaceCrux",
        data: function () {
            return {
                code: 0,
                imageIndex: 0,
                imageUrl: "",
                codeImageSrc: "",
                verCode: "",
                loading: false,
                imageWidth: 0, //图片本身宽度
                imageHeight: 0, //图片本身高度
                resultImageUrl: require("../../assets/images/face_check_example1.png"),
                resultData: "",
                isShowResultDataArea: false,
                isShowCodeArea: false,
                submitIsSuccess: false,
                faceAreaList:[],
                exampleArr : [
                    "208029bf-3ba2-47c0-80b9-f2d34d00611b.png",
                    "5ede6558-399d-46a4-aedb-6ba7d11a5c55.jpeg",
                    "e7a1b671-63fc-463e-b06d-1ff1254c7d1a.jpeg",
                    "fc83264f-5065-46fa-9f54-385416579b07.png",
                ],
                images: [
                    {src: require("../../assets/images/face_check_example1.png")},
                    {src: require("../../assets/images/face_check_example2.jpeg?v=1.2")},
                    {src: require("../../assets/images/face_check_example3.jpg?v=1.1")},
                    {src: require("../../assets/images/face_check_example4.png?v=1.1")}
                ]
            };
        },
        watch: {
            $route() {
                if (this.$route) {
                    this.common.scrollDemo();
                    this.clickImage(0);
                }
            }
        },
        mounted: function () {
            let self = this;

            $(".technology-scene-list li").on("click", function () {
                let code = $(this).data("code");
                if (code != self.code) {
                    $(".technology-scene-list li.active").removeClass("active");
                    $(this).addClass("active");
                    $(".scene-image-area").find("img").fadeOut(500).eq(code).fadeIn(500);
                    self.code = code
                }
            });
            self.$nextTick(() => {
                self.common.scrollDemo();
            });
        },
        created:function(){
            this.clickImage(0);
        },
        methods:{
            clickImage: function (index) {
                this.imageIndex = index;
                this.clearResultArea();
                this.setCheckImage(this.images[index].src);
                this.submitData(this.exampleArr[index],"imageUrl");
            },
            submitVerCode: function () {
                let self = this;
                self.$refs.form.validate().then(success => {
                    if (success) {
                        axios.get(validCode + "?code=" + self.verCode).then((res) => {
                            let data = res.data;
                            if (data.success) {
                                self.isShowCodeArea = false;
                                if (self.imageUrl.length > 0) {
                                    this.clearResultArea();
                                    self.setCheckImage(self.imageUrl);
                                    self.submitData(self.imageUrl,"imageUrl")
                                } else {
                                    this.$refs.handelUploadInput.click()
                                }
                            } else {
                                self.$refs.form.setErrors({"verCode": [res.response.data.message]});
                            }
                        }).catch((res) => {
                            if (res.response.data !== undefined) {
                                self.$refs.form.setErrors({"verCode": [res.response.data.message]});
                            }
                        })
                    }
                })
            },
            startShowCodeArea: function () {
                this.isShowCodeArea = true;
                this.verCode = "";
                this.codeImageSrc = getVerCode + new Date().getTime();
            },
            refreshCode: function () {
                this.codeImageSrc = getVerCode + new Date().getTime();
            },
            cancelShowCodeArea: function () {
                this.isShowCodeArea = false;
                this.codeImageSrc = "";
                this.verCode = "";
            },
            fileUpload: function(e){
                let self = this;
                this.clearResultArea();
                let file = e.target.files[0];
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function (e) {
                    self.setCheckImage(this.result);
                };
                self.submitData(file, "imageData");
            },
            clearResultArea: function(){
                this.isShowResultDataArea = false;
                this.resultData = "";
                this.faceAreaList = [];
            },
            submitData: function(file, name)  {
                let self = this;
                let config = {headers: {"Content-Type": "multipart/form-data"}};
                let formData = new FormData();
                //参数
                formData.append(name, file);
                formData.append("appKey", AppKey);
                formData.append("appSecret", AppSecret);
                formData.append("returnType", 3);
                formData.append("code", self.verCode);
                axios.post(demoDetect, formData, config).then(res => {
                    let data = res.data;
                    self.loading = false;
                    if (data && data.success){
                        self.submitIsSuccess = true;
                        self.setFaceArea(data);
                        self.resultData = JSON.stringify(data.collection[0], null, 2)
                    }
                }).catch(error => {
                    console.log(error);
                });
            },
            setCheckImage: function(url){
                var self = this;
                var imgObj = document.createElement("img");
                imgObj.src = url;
                imgObj.onload = function(){
                    self.imageWidth = imgObj.width;
                    self.imageHeight = imgObj.height;
                    self.$refs.resultImage.style.cssText = self.imageWidth/self.imageHeight > 600/370 ? "height:auto;width:100%" : "width:auto;height:100%" ;
                    self.resultImageUrl=url;
                    self.loading = true;
                    self.isShowResultDataArea = false;
                }
            },
            setFaceArea: function(data){
                let self = this;
                self.faceAreaList = [];
                let imageShowWidth = self.$refs.resultImage.width;
                let imageShowHeight = self.$refs.resultImage.height;
                self.$refs.resultShowArea.innerHTML = "";
                let facesArr = data.collection[0].faces;
                let length = facesArr.length;
                let arr = [];
                for(let i = 0; i < length; i ++){
                    let mark = facesArr[i].landmark;
                    //根据图片实际尺寸计算实际的坐标和框的大小
                    for (let j = 0; j < mark.length; j++) {
                        let json = {
                            "left": imageShowWidth/self.imageWidth * mark[j].x + (600 - imageShowWidth) / 2,
                            "top": imageShowHeight/self.imageHeight * mark[j].y + (370 - imageShowHeight) / 2,
                        };
                        arr.push(json);
                    }
                }
                self.faceAreaList = arr;
            },
            showResultArea: function (type) {
                this.isShowResultDataArea = type === 1;
            }
        },
        components: {
            ValidationProvider
            , LoadIngArea
            , TechnicalHeader
            , ValidationObserver
            , extend
        }
    }
</script>

<style scoped>
    @import "../../assets/css/face_common.css";
    .technology-title-area{
        background: url(../../assets/images/face_crux_bg.png) center no-repeat;
        background-size: cover;
    }
    .technology-scene-list{
        width: 400px;
    }
    .describe-image-area{
        padding-top: 135px;
    }
    .face-crux-point{
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: #51B3F8;
        position: absolute;
        left: 0;
        top: 0;
    }
</style>