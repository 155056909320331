<template>
    <div>
        <TechnicalHeader
                icon="face_contrast_icon"
                name="人脸对比技术"
                title="比对两张人脸的相似度。"
                domId="FaceContrast"
        />
        <main>
            <div class="technology-area">
                <div class="technology-content">
                    <div class="describe-image-area">
                        <img src="../../assets/images/face_contrast_describe.png">
                    </div>
                    <div class="technology-describe-area">
                        <p class="technology-describe-title">技术描述</p>
                        <p class="technology-describe-text">
                            通过提供的两张人脸，进行深度分析计算，获取两张人脸相似的置信度、阈值。立足于我们的安防和业务系统大数据平台，合成了高质量的大规模数据训练集，使用深度神经网络训练，获得高性能的人脸识别算法，能够在各种场景下执行快速、准确的人脸比对。</p>
                        <div class="technology-line"></div>
                    </div>
                </div>
            </div>

            <div id="demo-content" ref="demoContent" class="technology-area technology-area-inverse">
                <div class="technology-content">
                    <div class="technology-example">
                        <p class="technology-example-title">功能演示</p>
                        <p class="technology-example-text">马上体验鱼住人脸对比能力，请上传一张本地图片或提供图片URL。</p>
                        <ValidationObserver ref="form">
                            <form class="technology-form technology-left-form">
                                <button class="title-button title-button-primary upload-btn upload-file-button"
                                        @click="startShowCodeArea(0)"
                                        type="button">
                                    <i class="fa fa-cloud-upload"></i>
                                    <span data-locale="upload">立即上传</span>
                                </button>
                                <div class="upload-file-box-area">
                                    <input accept="image/jpeg,image/jpg,image/png"
                                           @change="fileUpload($event,0)"
                                           ref="handelUploadInput0"
                                           class="upload-file-box" type="file" name="">
                                </div>
                                <div class="technology-form-group">
                                    <input data-placeholder="imageBox" class="upload-file-url-box"
                                           v-model="imageUrl[0]"
                                           placeholder="图片URL" type="tetx" name="">
                                </div>
                                <div class="verification-code-area" v-show="isShowCodeArea[0]">
                                    <!-- verification-code-right -->
                                    <div class="verification-code-box-area">
                                        <ValidationProvider mode="lazy" vid="leftCode" name="验证码"
                                                            rules="codeRequired|verCode" v-slot="{ errors }">
                                            <input v-model="verCode[0]" data-placeholder="verificationCodeBox"
                                                   type="text"
                                                   placeholder="请输入验证码">
                                            <p class="verification-code-message">{{ errors[0] }}</p>
                                        </ValidationProvider>
                                    </div>
                                    <div class="verification-image-area">
                                        <img class="verification-code-image" alt="验证码"
                                             :src="codeImageSrc[0]">
                                        <a class="refresh-verification-code" href="javascript:void(0)"
                                           @click="refreshCode(0)"
                                        >看不清，换一张</a>
                                    </div>
                                    <div class="verification-btn-area">
                                        <button class="btn btn-default cancel-verification-code"
                                                @click="cancelShowCodeArea(0)"
                                                type="button">取消
                                        </button>
                                        <button class="btn btn-primary save-verification-code"
                                                @click="submitVerCode(0)"
                                                type="button">确定
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <form class="technology-form technology-right-form">
                                <button class="title-button title-button-primary upload-btn upload-file-button"
                                        @click="startShowCodeArea(1)"
                                        type="button">
                                    <i class="fa fa-cloud-upload"></i>
                                    <span data-locale="upload">立即上传</span>
                                </button>
                                <div class="upload-file-box-area">
                                    <input accept="image/jpeg,image/jpg,image/png"
                                           class="upload-file-box"
                                           @change="fileUpload($event,1)"
                                           ref="handelUploadInput1"
                                           type="file" name="">
                                </div>
                                <div class="technology-form-group">
                                    <input data-placeholder="imageBox" class="upload-file-url-box"
                                           v-model="imageUrl[1]"
                                           placeholder="图片URL" type="text" name="">
                                </div>
                                <div class="verification-code-area" v-show="isShowCodeArea[1]">
                                    <!-- verification-code-right -->
                                    <div class="verification-code-box-area">
                                        <ValidationProvider mode="lazy" vid="rightCode" name="验证码"
                                                            rules="codeRequired|verCode" v-slot="{ errors }">
                                            <input v-model="verCode[1]" data-placeholder="verificationCodeBox"
                                                   type="text"
                                                   placeholder="请输入验证码">
                                            <p class="verification-code-message">{{ errors[0] }}</p>
                                        </ValidationProvider>
                                    </div>
                                    <div class="verification-image-area">
                                        <img class="verification-code-image" alt="验证码"
                                             :src="codeImageSrc[1]">
                                        <a class="refresh-verification-code" href="javascript:void(0)"
                                           @click="refreshCode(1)"
                                        >看不清，换一张</a>
                                    </div>
                                    <div class="verification-btn-area">
                                        <button class="btn btn-default cancel-verification-code"
                                                @click="cancelShowCodeArea(1)"
                                                type="button">取消
                                        </button>
                                        <button class="btn btn-primary save-verification-code"
                                                @click="submitVerCode(1)"
                                                type="button">确定
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                    <div class="technology-example-result">
                        <div class="example-content">
                            <div class="example-image-area">
                                <img v-for="(data,index) in exampleImages[0]" @click="onDemoImageClick(0,index)"
                                     :src="data.src"
                                     :class="index===exampleIndex[0]?'active':''"/>
                            </div>
                            <div class="example-active-image-area">
                                <div class="example-active-image-content">
                                    <img id="result-image-left" ref="resultImage0" :src="resultImageUrl[0]">
                                    <div id="result-show-area-left">
                                        <div v-for="data in faceAreaList[0]"
                                             :style="{width:data.width+'px',height:data.height+'px',left:data.left+'px',top:data.top+'px'}"
                                             class="face-border-box"></div>
                                    </div>
                                    <LoadIngArea v-show="loading[0]"/>
                                </div>
                            </div>
                            <div class="example-result-area">
                                <p class="example-result-title">VS</p>
                                <p id="example-result" class="example-result" v-show="compareSuccess">相似率：<span>{{confidence}}</span>%
                                </p>
                                <button @click="showResultArea(1)"
                                        v-show="compareSuccess"
                                        type="button" class="result-data-btn">Response JSON
                                </button>
                            </div>
                            <div class="example-image-area">
                                <img v-for="(data,index) in exampleImages[1]" :src="data.src"
                                     @click="onDemoImageClick(1,index)"
                                     :class="index===exampleIndex[1]?'active':''"/>
                            </div>
                            <div class="example-active-image-area">
                                <div class="example-active-image-content">
                                    <img id="result-image-right" ref="resultImage1" :src="resultImageUrl[1]">
                                    <div id="result-show-area-right">
                                        <div v-for="data in faceAreaList[1]"
                                             :style="{width:data.width+'px',height:data.height+'px',left:data.left+'px',top:data.top+'px'}"
                                             class="face-border-box"></div>
                                    </div>
                                    <LoadIngArea v-show="loading[1]"/>
                                </div>
                            </div>
                        </div>
                        <div v-show="isShowResultDataArea" class="result-data-area">
                            <pre id="result-data">{{resultData}}</pre>
                            <button @click="showResultArea(0)"
                                    type="button" class="result-data-back-btn"><i class="fa fa-remove"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="technology-area">
                <div class="technology-content">
                    <p class="technology-scene-title">应用场景</p>
                    <ul class="technology-scene-list">
                        <li data-code="0" class="active">
                            <span>门禁系统</span>
                            <div class="scene-line"></div>
                        </li>
                        <li data-code="1">
                            <span>机场安检</span>
                            <div class="scene-line"></div>
                        </li>
                    </ul>

                    <div class="scene-image-area">
                        <img src="../../assets/images/face_contrast_scene1.png">
                        <img src="../../assets/images/face_contrast_scene2.png">
                    </div>
                </div>
            </div>

            <div class="technology-area technology-buy-area">
                <div class="technology-content">
                    <p class="technology-buy-title">即刻拥有鱼住人脸对比技术</p>
                    <p class="technology-buy-text">享受更高的性价比和技术优势</p>
                    <a href="price.html" class="buy-btn">立即购买</a>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import TechnicalHeader from '@/components/TechnicalHeader.vue';
    import LoadIngArea from '@/components/LoadIngArea';
    import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
    import {required} from 'vee-validate/dist/rules';
    import axios from 'axios';
    import {getVerCode, validCode, demoCompare, AppKey, AppSecret} from '../../assets/js/api';
    import {showNormalModal} from "../../assets/js/common";

    extend('codeRequired', {
        ...required,
        message: '请填写验证码！'
    });
    // Add a rule.
    extend('verCode', {
        validate: value => value.length > 0 && /^(\d|\w){4}$/.test(value),
        message: '请输入正确的4位验证码'
    });
    export default {
        name: "FaceCompare",
        data: function () {
            return {
                compareSuccess: false,
                confidence: 0,
                verificationCode: "",
                code: 0,
                verCode: {0: "", 1: ""},
                exampleIndex: {0: 0, 1: 0},
                imageUrl: {0: "", 1: ""},
                codeImageSrc: {0: "", 1: ""},
                loading: {0: false, 1: false},
                imageWidth: {0: 0, 1: 0}, //图片本身宽度
                imageHeight: {0: 0, 1: 0}, //图片本身高度
                resultImageUrl: {
                    0: "",
                    1: "",
                },
                resultData: "",
                isShowResultDataArea: false,
                isShowCodeArea: {0: false, 1: false},
                submitIsSuccess: false,
                faceAreaList: {},
                exampleImages: [
                    [
                        {src: require("../../assets/images/face_contrast_example1.jpeg")},
                        {src: require("../../assets/images/face_contrast_example2.png")},
                        {src: require("../../assets/images/face_contrast_example3.png")}
                    ],
                    [
                        {src: require("../../assets/images/face_contrast_example4.png")},
                        {src: require("../../assets/images/face_contrast_example5.png")},
                        {src: require("../../assets/images/face_contrast_example6.jpeg")}
                    ]
                ],
                exampleToken: {
                    0: [
                        "7B35711D-CDBD-404E-A466-2A4694F002F7",
                        "C9C090E6-3FE3-4285-B9EE-0C7325F422C4",
                        "F3F6841C-B334-462C-AC3B-D7A84D734521"
                    ],
                    1: [
                        "F73AD925-765A-4979-AEEE-116CC1B93F79",
                        "53822ADB-9FE4-4671-8D3C-642E399DFC89",
                        "B39812A4-6DDD-4146-8E24-86292AAEC5E8"
                    ]
                },
                model: {
                    url: ["imageUrlOne", "imageUrlTwo"],
                    data: ["imageDataOne", "imageDataTwo"],
                    token: ["objectTokenOne", "objectTokenTwo"]
                },
                compareData: {
                    0: {
                        type: "objectTokenOne",
                        value: "7B35711D-CDBD-404E-A466-2A4694F002F7",
                    },
                    1: {
                        type: "objectTokenTwo",
                        value: "F73AD925-765A-4979-AEEE-116CC1B93F79",
                    }
                }
            };
        },
        watch: {
            $route() {
                if (this.$route) {
                    this.common.scrollDemo();
                    this.onDemoImageClick(0,0);
                }
            }
        },
        mounted: function () {
            let self = this;
            self.$nextTick(() => {
                self.common.scrollDemo();
            });
            $(".technology-scene-list li").on("click", function () {
                let code = $(this).data("code");
                if (code != self.code) {
                    $(".technology-scene-list li.active").removeClass("active");
                    $(this).addClass("active");
                    $(".scene-image-area").find("img").fadeOut(500).eq(code).fadeIn(500);
                    self.code = code
                }
            })
        },
        created: function () {
            this.setCheckImage(this.exampleImages[0][0].src, 0);
            this.setCheckImage(this.exampleImages[1][0].src, 1);
            this.submitData(0);
        },
        methods: {
            onDemoImageClick: function (type, index) {
                this.exampleIndex[type] = index;
                this.compareData[type].type = this.model.token[type];
                this.compareData[type].value = this.exampleToken[type][index];
                this.clearResultArea(type);
                this.setCheckImage(this.exampleImages[type][index].src, type);
                this.submitData(type);
            },
            startShowCodeArea: function (index) {
                let other = index === 0 ? 1 : 0;
                this.isShowCodeArea[index] = true;
                this.isShowCodeArea[other] = false;
                this.verCode = {0: "", 1: ""};
                this.refreshCode(index);
            },
            refreshCode: function (index) {
                this.codeImageSrc[index] = getVerCode + new Date().getTime();
            },
            cancelShowCodeArea: function (index) {
                this.isShowCodeArea[index] = false;
                this.codeImageSrc = {0: "", 1: ""};
                this.verCode = {0: "", 1: ""};
            },
            submitVerCode: function (index) {
                let self = this;
                let type = index === 0 ? "leftCode" : "rightCode";
                self.$refs.form.validateWithInfo(type).then(success => {
                    if (success) {
                        axios.get(validCode + "?code=" + self.verCode[index]).then((res) => {
                            let data = res.data;
                            self.verificationCode = data.collection[0];
                            if (data.success) {
                                self.isShowCodeArea[index] = false;
                                if (self.imageUrl[index].length > 0) {
                                    let url = self.imageUrl[index];
                                    let reg = /^((http|https):\/\/([\w\-]+\.)+[\w\-]+(\/[\w\u4e00-\u9fa5\-\.\/?\@\%\!\&=\+\~\:\#\;\,]*)?)(jpg|png|jpeg)$/;

                                    //如果用户输入了图片url,且url格式不正确
                                    if (url && !reg.test(url)) {
                                        showNormalModal("图片URL地址不正确或图片格式不支持，仅支持jpg,png,jpeg");
                                        return false;
                                    }
                                    self.compareData[index] = {
                                        type: self.model.url[index],
                                        value: self.imageUrl[index],
                                    };
                                    this.clearResultArea(index);
                                    self.setCheckImage(self.imageUrl[index], index);
                                    self.submitData(index)
                                } else {
                                    this.$refs["handelUploadInput" + index].click()
                                }
                            } else {
                                self.$refs.form.setErrors({type: [res.response.data.message]});
                            }
                        }).catch((res) => {
                            if (res.response !== undefined && res.response.data !== undefined) {
                                if (index === 0) {
                                    self.$refs.form.setErrors({"leftCode": [res.response.data.message]});
                                } else {
                                    self.$refs.form.setErrors({"rightCode": [res.response.data.message]});
                                }
                            }
                        })
                    }
                })
            },

            fileUpload: function (e, index) {
                let self = this;
                this.clearResultArea(index);
                let file = e.target.files[0];
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function (e) {
                    self.setCheckImage(this.result, index);
                };
                self.compareData[index] = {
                    type: self.model.data[index],
                    value: file,
                };
                self.submitData(index);
            },
            clearResultArea: function (index) {
                this.isShowResultDataArea = false;
                this.resultData = "";
                this.faceAreaList[index] = [];
            },
            submitData: function (type) {
                let self = this;
                let config = {headers: {"Content-Type": "multipart/form-data"}};
                let formData = new FormData();
                //参数
                formData.append(this.compareData["0"].type, this.compareData["0"].value);
                formData.append(this.compareData["1"].type, this.compareData["1"].value);
                formData.append("appKey", AppKey);
                formData.append("appSecret", AppSecret);
                formData.append("code", self.verificationCode);
                axios.post(demoCompare, formData, config).then(res => {
                    let data = res.data;
                    self.loading = {0: false, 1: false};
                    if (data && data.success) {
                        self.submitIsSuccess = true;
                        self.confidence = data.collection[0].confidence;
                        self.compareSuccess = true;
                        if (data.collection[0] !== undefined && data.collection[0].faceOne !== undefined) {
                            self.setFaceArea(data.collection[0].faceOne, 0);
                        }
                        if (data.collection[0] !== undefined && data.collection[0].faceTwo !== undefined) {
                            self.setFaceArea(data.collection[0].faceTwo, 1);
                        }
                        self.resultData = JSON.stringify(data.collection[0], null, 2)
                    }
                }).catch(error => {
                    if (error.response !== undefined && error.response.message !== undefined){
                        showNormalModal(error.response.message);
                        return false;
                    } else{
                        showNormalModal("服务器出错了，请稍后再试");
                        return false;
                    }
                    console.log(error);
                });
            },
            setCheckImage: function (url, i) {
                var self = this;
                var imgObj = document.createElement("img");
                imgObj.src = url;
                imgObj.onload = function () {
                    self.imageWidth[i] = imgObj.width;
                    self.imageHeight[i] = imgObj.height;
                    self.$refs["resultImage" + i].style.cssText = self.imageWidth[i] / self.imageHeight[i] > 310 / 310 ? "height:auto;width:100%" : "width:auto;height:100%";
                    self.resultImageUrl[i] = url;
                    self.loading[i] = true;
                    self.isShowResultDataArea = false;
                }
            },
            setFaceArea: function (facesArr, index) {
                let self = this;
                let imageShowWidth = 0, imageShowHeight = 0;
                if (index === 0) {
                    imageShowWidth = self.$refs['resultImage0'].clientWidth;
                    imageShowHeight = self.$refs['resultImage0'].clientHeight;
                } else {
                    imageShowWidth = self.$refs['resultImage1'].clientWidth;
                    imageShowHeight = self.$refs['resultImage1'].clientHeight;
                }
                self.faceAreaList[index] = [];
                let length = facesArr.length;
                let arr = [];
                for (let i = 0; i < length; i++) {
                    let face = facesArr[i].faceLocate;
                    //根据图片实际尺寸计算实际的坐标和框的大小
                    let json = {
                        "left": imageShowWidth / self.imageWidth[index] * face.left + (310 - imageShowWidth) / 2,
                        "top": imageShowHeight / self.imageHeight[index] * face.top + (310 - imageShowHeight) / 2,
                        "width": imageShowWidth / self.imageWidth[index] * face.width,
                        "height": imageShowHeight / self.imageHeight[index] * face.height
                    };
                    arr.push(json);
                }
                self.faceAreaList[index] = arr;
            },
            showResultArea: function (type) {
                this.isShowResultDataArea = type === 1;
            }
        },
        components: {
            ValidationProvider
            , LoadIngArea
            , TechnicalHeader
            , ValidationObserver
            , extend
        }
    }
</script>

<style scoped>
    @import "../../assets/css/face_common.css";

    .technology-title-area {
        background: url(../../assets/images/face_contrast_bg.png) center no-repeat;
        background-size: cover;
    }

    .technology-scene-list {
        width: 400px;
    }

    .technology-example {
        padding-top: 90px;
        width: 100%;
    }


    .technology-example-result {
        padding-top: 20px;
    }

    .example-image-area img {
        width: 90px;
        height: 90px;
        margin-right: 0;
        margin-bottom: 20px;
    }

    .example-image-area img:last-child {
        margin-bottom: 0px;
    }

    .technology-example-result {
        width: 100%;
    }

    .example-image-area {
        width: 140px;
        height: 310px;
        display: inline-block;
        float: left;
    }

    .example-active-image-area {
        display: table;
        width: 310px;
        height: 310px;
        float: left;
        background-color: #eee;
    }

    .example-active-image-content {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        width: 310px;
        height: 310px;
        position: relative;
    }

    .example-result-area {
        float: left;
        width: 300px;
        height: 310px;
        text-align: center;
    }

    .result-data-btn {
        position: relative;
        left: 0;
        top: 0;
        margin-top: 50px;
    }

    .example-result-title {
        color: #3544ff;
        font-size: 48px;
        margin-top: 60px;
    }

    .example-result {
        margin-top: 25px;
        font-size: 16px;
        line-height: 22px;
    }

    .result-data-area {
        width: 100%;
        top: 17px;
        z-index: 99;
    }

    .result-data-area pre {
        height: 316px;
        margin-left: -3px;
    }
</style>