<template>
    <div>
        <TechnicalHeader
                icon="face-check-icon"
                name="人脸检测技术"
                title="对于任意一幅给定的图像，采用一定的策略对其进行搜索以确定其中是否含有人脸，如果是则返回一脸的位置、大小和姿态。"
                domId="FaceDetection"
        />
        <main>
            <div class="technology-area">
                <div class="technology-content">
                    <div class="describe-image-area">
                        <img src="../../assets/images/face_check_describe.png">
                    </div>
                    <div class="technology-describe-area">
                        <p class="technology-describe-title" data-locale="technologyDescribe">技术描述</p>
                        <p class="technology-describe-text" data-locale="faceCheckDescribe">
                            检测图中的人脸，并为人脸标记出边框。检测出人脸后，可对人脸进行分析，获得眼、口、鼻轮廓等72个关键点定位准确识别多种人脸属性，如性别，年龄，表情等信息。该技术可适应大角度侧脸，遮挡，模糊，表情变化等各种实际环境。</p>
                        <div class="technology-line"></div>
                    </div>
                </div>
            </div>

            <div id="demo-content" ref="demoContent" class="technology-area technology-area-inverse">
                <div class="technology-content">
                    <div class="technology-example">
                        <p class="technology-example-title" data-locale="demonstration">功能演示</p>
                        <p class="technology-example-text" data-locale="faceCheckDemonstration">
                            马上体验鱼住人脸检测能力，请上传一张本地图片或提供图片URL。</p>
                        <ValidationObserver ref="form">
                            <form class="technology-form">
                                <button id="upload-file-button" class="title-button title-button-primary upload-btn"
                                        type="button" @click="startShowCodeArea">
                                    <i class="fa fa-cloud-upload"></i>
                                    <span data-locale="upload">立即上传</span>
                                </button>
                                <div class="upload-file-box-area">
                                    <input accept="image/jpeg,image/jpg,image/png"
                                           @change="fileUpload"
                                           ref="handelUploadInput" class="upload-file-box" type="file"
                                           name="">
                                </div>
                                <div class="technology-form-group">
                                    <input id="upload-file-url-box" data-placeholder="imageBox"
                                           class="upload-file-url-box"
                                           v-model="imageUrl"
                                           placeholder="图片URL" type="text" name="">
                                </div>
                                <div id="verification-code-area" class="verification-code-area" v-show="isShowCodeArea">
                                    <!-- verification-code-right -->
                                    <div class="verification-code-box-area">
                                        <ValidationProvider mode="lazy" vid="verCode" name="验证码"
                                                            rules="codeRequired|verCode" v-slot="{ errors }">
                                            <input v-model="verCode" data-placeholder="verificationCodeBox" type="text"
                                                   placeholder="请输入验证码">
                                            <p class="verification-code-message">{{ errors[0] }}</p>
                                        </ValidationProvider>
                                    </div>
                                    <!-- <p class="text-muted">输入下图中的字符</p> -->
                                    <div class="verification-image-area">
                                        <img id="verification-code-image" alt="验证码" :src="codeImageSrc">
                                        <a id="refresh-verification-code" href="javascript:void(0)"
                                           @click="refreshCode">看不清，换一张</a>
                                    </div>
                                    <div class="verification-btn-area">
                                        <button id="cancel-verification-code"
                                                @click="cancelShowCodeArea"
                                                class="btn btn-default" type="button">取消
                                        </button>
                                        <button @click="submitVerCode" class="btn btn-primary" type="button">确定</button>
                                    </div>
                                </div>
                            </form>
                        </ValidationObserver>
                        <p class="example-prompt" data-locale="selectDemonstrationImage">您也可以选择下面图片，系统将自动进行识别。</p>

                        <div class="example-image-area">
                            <img v-for="(item,index) in images" @click="clickImage(index)"
                                 :class="imageIndex===index?'active':''" :src="item.src">
                        </div>
                    </div>
                    <div class="technology-example-result">
                        <div v-show="!isShowResultDataArea" class="result-image-area">
                            <div class="result-content-area">
                                <img ref="resultImage" class="result-image"
                                     :src="resultImageUrl">
                                <button @click="showResultArea(1)" type="button" class="result-data-btn">Response JSON </button>
                                <div ref="resultShowArea">
                                    <div v-for="data in faceAreaList"
                                         :style="{width:data.width+'px',height:data.height+'px',left:data.left+'px',top:data.top+'px'}"
                                         class="face-border-box"></div>
                                </div>
                                <LoadIngArea v-show="loading"/>
                            </div>
                        </div>
                        <div v-show="isShowResultDataArea" class="result-data-area">
                            <pre id="result-data">{{resultData}}</pre>
                            <button @click="showResultArea(0)" type="button" class="result-data-back-btn"><i
                                    class="fa fa-remove"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="technology-area">
                <div class="technology-content">
                    <p class="technology-scene-title" data-locale="applyScene">应用场景</p>
                    <ul class="technology-scene-list">
                        <li data-code="0" class="active">
                            <span data-locale="autofocus">自动对焦</span>
                            <div class="scene-line"></div>
                        </li>
                        <li data-code="1">
                            <span data-locale="databaseManage">数据库管理</span>
                            <div class="scene-line"></div>
                        </li>
                        <li data-code="2">
                            <span data-locale="peopleStreamCheck">人流量检测</span>
                            <div class="scene-line"></div>
                        </li>
                    </ul>

                    <div class="scene-image-area">
                        <img src="../../assets/images/face_check_scene1.png">
                        <img src="../../assets/images/face_check_scene2.png">
                        <img src="../../assets/images/face_check_scene3.png">
                    </div>
                </div>
            </div>
            <div class="technology-area technology-buy-area">
                <div class="technology-content">
                    <p class="technology-buy-title" data-locale="faceCheckBuy">即刻拥有鱼住人脸检测技术</p>
                    <p class="technology-buy-text" data-locale="buyText">享受更高的性价比和技术优势</p>
                    <a href="price.html" class="buy-btn" data-locale="buyBtnText">立即购买</a>
                </div>
            </div>
        </main>
    </div>
</template>
<script>
    import TechnicalHeader from '@/components/TechnicalHeader.vue';
    import LoadIngArea from '@/components/LoadIngArea';
    import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
    import {required} from 'vee-validate/dist/rules';
    import axios from 'axios';
    import {getVerCode, validCode, demoDetect, AppKey, AppSecret} from '../../assets/js/api';

    extend('codeRequired', {
        ...required,
        message: '请填写验证码！'
    });
    // Add a rule.
    extend('verCode', {
        validate: value => value.length > 0 && /^(\d|\w){4}$/.test(value),
        message: '请输入正确的4位验证码'
    });
    export default {
        name: "FaceDetection",
        data: function () {
            return {
                code: 0,
                imageIndex: 0,
                imageUrl: "",
                codeImageSrc: "",
                verCode: "",
                loading: false,
                imageWidth: 0, //图片本身宽度
                imageHeight: 0, //图片本身高度
                resultImageUrl: require("../../assets/images/face_check_example1.png"),
                resultData: "",
                isShowResultDataArea: false,
                isShowCodeArea: false,
                submitIsSuccess: false,
                faceAreaList:[],
                exampleArr : [
                    "208029bf-3ba2-47c0-80b9-f2d34d00611b.png",
                    "5ede6558-399d-46a4-aedb-6ba7d11a5c55.jpeg",
                    "e7a1b671-63fc-463e-b06d-1ff1254c7d1a.jpeg",
                    "fc83264f-5065-46fa-9f54-385416579b07.png",
                ],
                images: [
                    {src: require("../../assets/images/face_check_example1.png")},
                    {src: require("../../assets/images/face_check_example2.jpeg?v=1.2")},
                    {src: require("../../assets/images/face_check_example3.jpg?v=1.1")},
                    {src: require("../../assets/images/face_check_example4.png?v=1.1")}
                ]
            };
        },
        watch: {
            $route() {
                if (this.$route) {
                    this.common.scrollDemo();
                    this.clickImage(0);
                }
            }
        },
        mounted: function () {
            let self = this;
            $(".technology-scene-list li").on("click", function () {
                let code = $(this).data("code");
                if (code != self.code) {
                    $(".technology-scene-list li.active").removeClass("active");
                    $(this).addClass("active");
                    $(".scene-image-area").find("img").fadeOut(500).eq(code).fadeIn(500);
                    self.code = code
                }
            });
            self.$nextTick(() => {
                self.common.scrollDemo();
            });
        },
        created:function(){
            this.clickImage(0);
        },
        methods: {
            clickImage: function (index) {
                this.imageIndex = index;
                this.clearResultArea();
                this.setCheckImage(this.images[index].src);
                this.submitData(this.exampleArr[index],"imageUrl");
            },
            submitVerCode: function () {
                let self = this;
                self.$refs.form.validate().then(success => {
                    if (success) {
                        axios.get(validCode + "?code=" + self.verCode).then((res) => {
                            let data = res.data;
                            if (data.success) {
                                self.isShowCodeArea = false;
                                if (self.imageUrl.length > 0) {
                                    self.clearResultArea();
                                    self.setCheckImage(self.imageUrl);
                                    self.submitData(self.imageUrl,"imageUrl")
                                } else {
                                    self.$refs.handelUploadInput.click()
                                }
                            } else {
                                self.$refs.form.setErrors({"verCode": [res.response.data.message]});
                            }
                        }).catch((res) => {
                            if (res.response.data !== undefined) {
                                self.$refs.form.setErrors({"verCode": [res.response.data.message]});
                            }
                        })
                    }
                })
            },
            startShowCodeArea: function () {
                this.isShowCodeArea = true;
                this.verCode = "";
                this.codeImageSrc = getVerCode + new Date().getTime();
            },
            refreshCode: function () {
                this.codeImageSrc = getVerCode + new Date().getTime();
            },
            cancelShowCodeArea: function () {
                this.isShowCodeArea = false;
                this.codeImageSrc = "";
                this.verCode = "";
            },
            fileUpload: function(e){
                let self = this;
                self.clearResultArea();
                let file = e.target.files[0];
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function (e) {
                    self.setCheckImage(this.result);
                };
                self.submitData(file, "imageData");
            },
            clearResultArea: function(){
                this.isShowResultDataArea = false;
                this.resultData = "";
                this.faceAreaList = [];
            },
            submitData: function(file, name)  {
                let self = this;
                let config = {headers: {"Content-Type": "multipart/form-data"}};
                let formData = new FormData();
                //参数
                formData.append(name, file);
                formData.append("appKey", AppKey);
                formData.append("appSecret", AppSecret);
                formData.append("returnType", 3);
                formData.append("code", self.verCode);
                axios.post(demoDetect, formData, config).then(res => {
                    let data = res.data;
                    self.loading = false;
                    if (data && data.success){
                        self.submitIsSuccess = true;
                        self.setFaceArea(data);
                        self.resultData = JSON.stringify(data.collection[0], null, 2)
                    }
                }).catch(error => {
                    console.log(error);
                });
            },
            setCheckImage: function(url){
                var self = this;
                var imgObj = document.createElement("img");
                imgObj.src = url;
                imgObj.onload = function(){
                    self.imageWidth = imgObj.width;
                    self.imageHeight = imgObj.height;
                    self.$refs.resultImage.style.cssText = self.imageWidth/self.imageHeight > 600/370 ? "height:auto;width:100%" : "width:auto;height:100%" ;
                    self.resultImageUrl=url;
                    self.loading = true;
                    self.isShowResultDataArea = false;
                }
            },
            setFaceArea: function(data){
                let self = this;
                self.faceAreaList = [];
                let imageShowWidth = self.$refs.resultImage.width;
                let imageShowHeight = self.$refs.resultImage.height;
                self.$refs.resultShowArea.innerHTML = "";
                let facesArr = data.collection[0].faces;
                let length = facesArr.length;
                let arr = [];
                for(let i = 0; i < length; i ++){
                    let face = facesArr[i].faceLocate;
                    //根据图片实际尺寸计算实际的坐标和框的大小
                    let json = {
                        "left": imageShowWidth/self.imageWidth * face.left + (600 - imageShowWidth) / 2,
                        "top": imageShowHeight/self.imageHeight * face.top + (370 - imageShowHeight) / 2,
                        "width": imageShowWidth/self.imageWidth * face.width,
                        "height": imageShowHeight/self.imageHeight * face.height
                    };
                    json.age = facesArr[i].attributes.age;
                    json.gender = facesArr[i].attributes.gender;
                    arr.push(json);
                }
                self.faceAreaList = arr;
            },
            showResultArea: function (type) {
                this.isShowResultDataArea = type === 1;
            }
        },
        components: {
            ValidationProvider
            , LoadIngArea
            , TechnicalHeader
            , ValidationObserver
            , extend
        }
    }
</script>

<style scoped>
    @import "../../assets/css/face_common.css";

    .face-check-message {
        position: absolute;
        top: 15px;
        left: 15px;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        background-color: #3544ff;
        text-align: center;
        color: #fff;
        padding-top: 25px;
        margin-bottom: 5px;
    }

    .face-check-gender {
        font-size: 22px;
        margin-bottom: 0;
    }

    .face-check-age {
        font-size: 16px;
        margin-bottom: 0;
    }
</style>