<template>
    <main>
        <div class="price-banner">
            <div class="price-description text-center">
                <h1>WEB API</h1>
                <p>通过API文档，可以快速地进行人脸识别、视频结构化等开发</p>
<!--                <a href="face_check_index.html#demo-content" class="lg-btn blue-btn">免费体验</a>-->
<!--                <a href="price.html" class="lg-btn border-btn">商务合作</a>-->
            </div>
        </div>

        <div class="feature-wrap feature-wrap1 text-center">
            <h2 class="content-title">响应速度快</h2>
            <p class="content-description">基于http协议访问，利用现在无所不在的互联网，快速的进行响应，提高用户产品</p>
            <div class="feature-img"></div>
        </div>
        <div class="feature-wrap feature-wrap2 text-center">
            <h2 class="content-title">多平台开发</h2>
            <p class="content-description">API可以广泛应用于各种浏览器、移动应用、及传统桌面应用</p>
            <div class="feature-img">
                <span class="feature-icon icon-browser"></span>
                <span class="feature-icon icon-mobile"></span>
                <span class="feature-icon icon-window"></span>
            </div>
        </div>
        <div class="feature-wrap feature-wrap3 text-center">
            <h2 class="content-title">接口标准化</h2>
            <p class="content-description">API接口遵循REST设计原则，采用RESTful风格，实现可重用性、简单性、可扩展性和组件可响应性的清晰分离</p>
            <div class="feature-img"></div>
        </div>
        <div class="feature-wrap feature-wrap4 text-center gray-background">
            <div class="feature-img"></div>
            <p class="content-description">针对HTTP调用者，我们提供了开放的WEB  API，利用HTTP简单、灵活、无处不在的、能够快速适应多种平台的特性，快速实现人脸检测、人脸关键点、人脸属性、人脸对比、性别识别、年龄识别、车辆特征识别、车牌识别等功能。</p>
        </div>

        <div class="test-area text-center">
            <h2 class="content-title">3步轻松接入WEB API</h2>
            <div class="test-group">
                <button type="button" class="md-btn blue-btn">完善信息</button>
                <span class="interval-point">·····</span>
                <button type="button" class="md-btn border-btn">创建API Key</button>
                <span class="interval-point">·····</span>
                <button type="button" class="md-btn border-btn">开始调用</button>
            </div>
        </div>
        <div class="experience-area text-center gray-background">
            <h2 class="content-title">即刻拥有鱼住WEB API技术</h2>
            <p class="content-description">享受更高性价比和技术优势</p>
            <a href="face_check_index.html#demo-content" class="red-btn experience-btn">立即体验</a>
        </div>
    </main>
</template>

<script>
    export default {
        name: "Api"
    }
</script>

<style scoped>
    @import "../../assets/css/product_api.css";
</style>