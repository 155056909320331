<template>
    <div id="common-content" class="js-article-wrap">
        <ol class="breadcrumb">
            <li><a href="javascript:void(0)">通用说明</a></li>
            <li class="active">通用说明</li>
        </ol>
        <article class="article-group">
            <h3 class="article-title">请求地址</h3>
            <div class="dashed-area request-area">
                <p class="ordinary-text">请求域名：api.aidoing.com.cn</p>
                <p class="ordinary-text">支持协议：http</p>
                <p class="ordinary-text">返回格式：JSON</p>
            </div>
        </article>
        <article class="article-group">
            <h3 class="article-title">关于objectToken</h3>
            <p class="ordinary-text">我们将检测出来的每个人脸信息进行缓存，并返给一个唯一的标识符。通过这个标识符，可以进行对比、分析等操作。缓存时间为48小时，我们建议您将objectToken添加到facearticlebrary(人脸库)。一张图像多次检测获取到的相同人脸的objectToken不一致。</p>
        </article>
    </div>
</template>

<script>
    export default {
        name: "DocCommon"
    }
</script>

<style scoped>

</style>