import { render, staticRenderFns } from "./DiscernmentCid.vue?vue&type=template&id=28de8a32&scoped=true&"
import script from "./DiscernmentCid.vue?vue&type=script&lang=js&"
export * from "./DiscernmentCid.vue?vue&type=script&lang=js&"
import style0 from "./DiscernmentCid.vue?vue&type=style&index=0&id=28de8a32&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28de8a32",
  null
  
)

export default component.exports