<template>
    <div>
        <TechnicalHeader
                icon="discernment_clothing_icon"
                name="衣着识别技术"
                title="检测视频（图像）中的人员穿着。"
                domId="ClothingRecognition"
        />
        <main>
            <div class="technology-area">
                <div class="technology-content">
                    <div class="describe-image-area">
                        <img src="../../assets/images/discernment_clothing_describe.png">
                    </div>
                    <div class="technology-describe-area">
                        <p class="technology-describe-title">技术描述</p>
                        <p class="technology-describe-text">检测用户提供视频（图像），通过深度神经网络算法进行分析，返回检测到的人员穿着信息，如上衣：长袖、短袖；下衣：长裤、短裤等，广泛运用于安防监控场景。基于深度神经网络实现的快速、精准、广泛的人体特征识别系统。</p>
                        <div class="technology-line"></div>
                    </div>
                </div>
            </div>

            <!-- <div id="demo-content" class="technology-area technology-area-inverse">
              <div class="technology-content">
                <div class="technology-example">
                  <p class="technology-example-title">功能演示</p>
                  <p class="technology-example-text">马上体验鱼住衣着识别能力，请上传一张本地图片或提供图片URL。</p>
                  <form class="technology-form">
                    <button id="upload-file-button" class="title-button title-button-primary upload-btn" type="button"><i class="fa fa-cloud-upload"></i> 立即上传</button>
                    <div class="upload-file-box-area">
                      <input accept="image/jpeg,image/jpg,image/png" class="upload-file-box" type="file" name="">
                    </div>
                    <div class="technology-form-group">
                      <input id="upload-file-url-box" class="upload-file-url-box" placeholder="图片URL" type="tetx" name="">
                    </div>
                    <div id="verification-code-area" class="verification-code-area">
                      <div class="verification-code-box-area">
                        <input id="verification-code-box" data-placeholder="verificationCodeBox" placeholder="请输入验证码" type="text" name="">
                        <p id="verification-code-message" class="verification-code-message"></p>
                      </div>
                      <div class="verification-image-area">
                        <img id="verification-code-image" alt="验证码" src="">
                        <a id="refresh-verification-code" href="javascript:void(0)">看不清，换一张</a>
                      </div>
                      <div class="verification-btn-area">
                        <button id="cancel-verification-code" class="btn btn-default" type="button">取消</button>
                        <button id="save-verification-code" class="btn btn-primary" type="button">确定</button>
                      </div>
                    </div>
                  </form>
                  <p class="example-prompt">您也可以选择下面图片，系统将自动进行识别。</p>

                  <div class="example-image-area">
                    <img class="active" src="discernment_clothing_example1.png?v=1.1">
                    <img src="discernment_clothing_example2.png?v=1.1">
                    <img src="discernment_clothing_example3.png?v=1.1">
                    <img src="discernment_clothing_example4.png?v=1.1">
                  </div>
                </div>
                <div class="technology-example-result">
                  <div class="result-image-area">
                    <div class="result-content-area">
                      <img id="result-image" src="discernment_clothing_example1.png">
                      <button id="result-data-btn" type="button" class="result-data-btn">Response JSON</button>
                      <div id="result-show-area" class="result-show-area"></div>
                      <div id="face-check-message" class="face-check-message"></div>
                      <div class="loading-area">
                        <div class="loadEffect">
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="result-data-area">
                    <pre id="result-data"></pre>
                    <button id="result-data-back-btn" type="button" class="result-data-back-btn"><i class="fa fa-remove"></i></button>
                  </div>
                </div>
              </div>
            </div> -->

            <div class="technology-area">
                <div class="technology-content">
                    <p class="technology-scene-title">应用场景</p>
                    <ul class="technology-scene-list">
                        <li data-code="0" class="active">
                            <span>视频电商</span>
                            <div class="scene-line"></div>
                        </li>
                        <li data-code="1">
                            <span>人员排查</span>
                            <div class="scene-line"></div>
                        </li>
                    </ul>

                    <div class="scene-image-area">
                        <img src="../../assets/images/discernment_clothing_scene1.png">
                        <img src="../../assets/images/discernment_clothing_scene2.png">
                    </div>
                </div>
            </div>

            <div class="technology-area technology-buy-area">
                <div class="technology-content">
                    <p class="technology-buy-title">即刻拥有鱼住衣着识别技术</p>
                    <p class="technology-buy-text">享受更高的性价比和技术优势</p>
                    <a href="price.html" class="buy-btn">立即购买</a>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import TechnicalHeader from '@/components/TechnicalHeader.vue';
    export default {
        name: "DiscernmentClothing",
        data: function () {
            return {
                code: 0
            };
        },
        mounted: function () {
            let self = this;
            $(".technology-scene-list li").on("click", function () {
                let code = $(this).data("code");
                if (code != self.code) {
                    $(".technology-scene-list li.active").removeClass("active");
                    $(this).addClass("active");
                    $(".scene-image-area").find("img").fadeOut(500).eq(code).fadeIn(500);
                    self.code = code
                }
            })
        },
        components: {
            TechnicalHeader
        }
    }
</script>

<style scoped>
    @import "../../assets/css/face_common.css";
    .technology-title-area{
        background: url(../../assets/images/discernment_clothing_bg.png) center no-repeat;
        background-size: cover;
    }
    .technology-scene-list{
        width: 400px;
    }
    .describe-image-area{
        padding-top: 96px;
    }
</style>