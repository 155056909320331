const getVerCode = "/vdinapi/web-api/v1/getKaptchaImage?";
const validCode = "/vdinapi/face/v1/validCode";
const demoDetect = "/vdinapi/face/v1/demo/detect";
const demoCompare = "/vdinapi/face/v1/demo/compare";

const getRechargeData = "/vcr/web-api/v1/goodsExplainTimes.json";
const getAllPurchase = "/vcr/web-api/v1/goodsExplainPeriod.json?goodsType=1";
const getAllSdkPrice = "/vcr/web-api/v1/goodsExplainPeriod.json?goodsType=2";

const AppKey = "188c15d87c36a394473e84cb8028ffad";
const AppSecret = "188c15d87c36a394473e84cb8028ffad";

module.exports = {
    getVerCode
    , validCode
    , demoDetect
    , AppKey
    , AppSecret
    , demoCompare
    , getRechargeData
    , getAllPurchase
    , getAllSdkPrice
};