<template>
    <div>
        <TechnicalHeader
                icon="discernment_id_card_bg"
                name="身份证云解码技术"
                title="提供居民二代身份证、港澳通行证 、护照以及CID（电子身份证）的云端解码，及真伪辨识功能。"
                domId=""
                :hideDoc=true
        />
        <main>
            <div class="technology-area small-technology-area">
                <div class="technology-content">
                    <div class="describe-image-area">
                        <img src="../../assets/images/discernment_id_card_describe.png">
                    </div>
                    <div class="technology-describe-area">
                        <p class="technology-describe-title">技术描述</p>
                        <p class="technology-describe-text">通过我们的SDK可以有效的解读出证件上的内容，这有别于市面上常见的OCR认证，我们不仅提供居民二代身份证的云解码，还能识别港澳通行证、护照及电子身份证（CID）;通过证件的解析，能有效的辨别证件的真伪。能够快速准确的解析证件信息，方便进行人证对比工作。</p>
                        <div class="technology-line"></div>
                    </div>
                </div>
            </div>

            <div class="technology-area">
                <div class="technology-content">
                    <p class="technology-scene-title">应用场景</p>
                    <ul class="technology-scene-list">
                        <li data-code="0" class="active">
                            <span>旅馆入住</span>
                            <div class="scene-line"></div>
                        </li>
                        <li data-code="1">
                            <span>车站安检识别</span>
                            <div class="scene-line"></div>
                        </li>
                    </ul>

                    <div class="scene-image-area">
                        <img src="../../assets/images/discernment_id_card_scene1.png">
                        <img src="../../assets/images/discernment_id_card_scene2.png">
                    </div>
                </div>
            </div>

            <div class="technology-area technology-buy-area">
                <div class="technology-content">
                    <p class="technology-buy-title">即刻拥有鱼住身份证云解码技术</p>
                    <p class="technology-buy-text">享受更高的性价比和技术优势</p>
                    <a href="price.html" class="buy-btn">立即购买</a>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import TechnicalHeader from '@/components/TechnicalHeader.vue'
    export default {
        name: "DiscernmentIdCard",
        data: function () {
            return {
                code: 0
            };
        },
        mounted: function () {
            let self = this;
            $(".technology-scene-list li").on("click", function () {
                let code = $(this).data("code");
                if (code != self.code) {
                    $(".technology-scene-list li.active").removeClass("active");
                    $(this).addClass("active");
                    $(".scene-image-area").find("img").fadeOut(500).eq(code).fadeIn(500);
                    self.code = code
                }
            })
        },
        components: {
            TechnicalHeader
        }
    }
</script>

<style scoped>
    @import "../../assets/css/face_common.css";
    .technology-title-area{
        background: url(../../assets/images/discernment_id_card_bg.png) center no-repeat;
        background-size: cover;
    }
    .technology-scene-list{
        width: 400px;
    }
</style>