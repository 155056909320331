<template>
  <div id="face-details" class="js-article-wrap" v-if="info">
    <ol class="breadcrumb" v-if="info.path">
      <li v-for="path in info.path"><a href="javascript:void(0)">{{ path }}</a></li>
    </ol>
    <article class="article-group">
      <h3 class="article-title">简介</h3>
      <p class="ordinary-text" v-html="info ? info.description :''">{{ info ? info.description : "" }}</p>
    </article>
    <article class="article-group">
      <h3 class="article-title">调用地址</h3>
      <pre class="dashed-area">{{ info ? info.url : "" }}</pre>
    </article>
    <article class="article-group">
      <h2 class="article-title">调用方式</h2>
      <pre class="dashed-area">{{ info ? info.method : "" }}</pre>
      <h2 class="article-title">类型</h2>
      <pre class="dashed-area">application/json</pre>
    </article>
    <article class="article-group" >
      <h3 class="article-title">请求参数</h3>
      <table class="table table-area">
        <thead>
        <tr>
          <th>参数名</th>
          <th>类型</th>
          <th>是否必需</th>
          <th>位置</th>
          <th>说明</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in info.params">
          <td>{{ item.name }}</td>
          <td>{{ item.type }}</td>
          <td>{{ item.required }}</td>
          <td>{{ item.position }}</td>
          <td>{{ item.desc }}</td>
        </tr>
        </tbody>
      </table>
      <template v-if="info&& info.requestDemo && info.requestDemo !== undefined">
        <p>请求示例：</p>
        <p v-if="info.requestDemo.url">URL：{{ info.requestDemo.url}}</p>
        <p v-if="info.requestDemo.demo">Body：</p>
        <pre v-if="info.requestDemo.demo" class="dashed-area">{{ info.requestDemo.demo }}</pre>
        <p v-if="info.requestDemo"><span style="color: red">*<strong>演示数据请勿在程序中使用</strong></span></p>
      </template>
    </article>
    <article class="article-group">
      <h3 class="article-title">返回参数</h3>
      <p>数据格式JSON：</p>
      <table class="table table-area">
        <thead>
        <tr>
          <th>字段</th>
          <th>类型</th>
          <th>说明</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in info.response">
          <td>{{ item.name }}</td>
          <td>{{ item.type }}</td>
          <td>{{ item.desc }}</td>
        </tr>
        </tbody>
      </table>

      <template v-if="info&& info.responseDemo">
        <p>请求成功返回示例：</p>
        <pre class="dashed-area">{{ info.responseDemo }}</pre>
      </template>

      <template v-if="info&& info.responseErrorDemo">
        <p>请求失败返回示例：</p>
        <pre class="dashed-area">{{ info.responseErrorDemo }}</pre>
      </template>
    </article>
    <template v-if="info&& info.errorList">
      <article class="article-group">
        <h3 class="article-title">错误信息</h3>
        <table class="table table-area error-table">
          <thead>
          <tr>
            <th>错误信息</th>
            <th>说明</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in info.errorList">
            <td>{{ item.code }}</td>
            <td>{{ item.message }}</td>
          </tr>
          </tbody>
        </table>
      </article>
    </template>
  </div>
</template>

<script>
export default {
  name: "DocOpenApiInfo",
  props: {
    info: {
      type: Object,
      default: () => null
    }
  }
}
</script>

<style scoped>

</style>