<template>
    <div>
        <TechnicalHeader
                icon="discernment_pass_check_icon"
                name="护照、港澳通行证识别技术"
                title="对国际通行护照及港澳通行证进行识别。"
                domId=""
                :hideDoc='true'
        />
        <main>
            <div class="technology-area small-technology-area">
                <div class="technology-content">
                    <div class="describe-image-area">
                        <img src="../../assets/images/discernment_pass_check_describe.png">
                    </div>
                    <div class="technology-describe-area">
                        <p class="technology-describe-title">技术描述</p>
                        <p class="technology-describe-text">能够识别国际通行护照及港澳通行证是我们产品的亮点之一，通过该功能可以快速、准确的获得人员信息，部署简单、操作方便、适用范围广泛。</p>
                        <div class="technology-line"></div>
                    </div>
                </div>
            </div>

            <div class="technology-area">
                <div class="technology-content">
                    <p class="technology-scene-title">应用场景</p>
                    <ul class="technology-scene-list">
                        <li data-code="0" class="active">
                            <span>员工证件管理</span>
                            <div class="scene-line"></div>
                        </li>
                        <li data-code="1">
                            <span>出入境检查</span>
                            <div class="scene-line"></div>
                        </li>
                    </ul>

                    <div class="scene-image-area">
                        <img src="../../assets/images/discernment_pass_check_scene1.png">
                        <img src="../../assets/images/discernment_pass_check_scene2.png">
                    </div>
                </div>
            </div>

            <div class="technology-area technology-buy-area">
                <div class="technology-content">
                    <p class="technology-buy-title">即刻拥有鱼住护照、港澳通行证识别技术</p>
                    <p class="technology-buy-text">享受更高的性价比和技术优势</p>
                    <a href="price.html" class="buy-btn">立即购买</a>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import TechnicalHeader from '@/components/TechnicalHeader.vue';
    export default {
        name: "DiscernmentPassport",
        data: function () {
            return {
                code: 0
            };
        },
        mounted: function () {
            let self = this;
            $(".technology-scene-list li").on("click", function () {
                let code = $(this).data("code");
                if (code != self.code) {
                    $(".technology-scene-list li.active").removeClass("active");
                    $(this).addClass("active");
                    $(".scene-image-area").find("img").fadeOut(500).eq(code).fadeIn(500);
                    self.code = code
                }
            })
        },
        components: {
            TechnicalHeader
        }
    }
</script>

<style scoped>
    @import "../../assets/css/face_common.css";
    .technology-title-area{
        background: url(../../assets/images/discernment_pass_check_bg.png) center no-repeat;
        background-size: cover;
    }
    .technology-scene-list{
        width: 400px;
    }
    .describe-image-area{
        padding-top: 200px;
    }
</style>