<template>
    <div>
        <main>
            <div class="price-banner">
                <div class="price-description text-center">
                    <h1>SDK</h1>
                    <p>基于SDK可以快速、准确的进行多种证件云端解码工作。读取证件信息，具有最权威的身份证明作用，得出可信度最高的人证验证结果。</p>
                </div>
            </div>

            <div class="feature-wrap feature-wrap1 text-center">
                <h2 class="content-title">解码证件种类多样化</h2>
                <p class="content-description">支持包括居民二代身份证、护照及港澳通行证、网络电子身份证（CID）等多种身份证件</p>
                <div class="feature-img"></div>
            </div>
            <div class="feature-wrap feature-wrap2 text-center">
                <h2 class="content-title">适用平台广泛</h2>
                <p class="content-description">SDK相关产品可以集成于Android移动应用、windows桌面应用等各种平台</p>
            </div>
            <div class="feature-wrap feature-wrap3 text-center">
                <h2 class="content-title">专业的技术支持</h2>
                <p class="content-description">我们的专业团队针对客户使用过程中遇到的问题进行快速准确的解决</p>
                <div class="feature-img"></div>
            </div>


            <div class="test-area text-center gray-background">
                <h2 class="content-title">3步轻松接入Web API</h2>
                <div class="test-group">
                    <button type="button" class="md-btn blue-btn">完善信息</button>
                    <span class="interval-point">·····</span>
                    <button type="button" class="md-btn border-btn">创建API Key</button>
                    <span class="interval-point">·····</span>
                    <button type="button" class="md-btn border-btn">开始调用</button>
                </div>
            </div>
            <div class="experience-area text-center">
                <h2 class="content-title">即刻拥有鱼住SDK技术</h2>
                <p class="content-description">享受更高性价比和技术优势</p>
                <a href="price.html" class="red-btn experience-btn">立即体验</a>
            </div>
        </main>
    </div>
</template>

<script>
    export default {
        name: "Api"
    }
</script>

<style scoped>
    @import "../../assets/css/product_sdk.css";
</style>