<template>
  <div style="overflow-y: hidden">
    <aside class="side-menu js-side-menu" style="overflow-y: scroll" ref="docMenu" id="docMenu">
      <!-- Nav tabs -->
      <ul class="nav nav-tabs" role="tablist">
        <li role="presentation" class="active">
          <a href="#openapi" aria-controls="api" role="tab" data-toggle="tab">
            <span>{{ document.title }}</span>
          </a>
        </li>
      </ul>
      <!-- Tab panes -->
      <div class="tab-content">
        <div role="tabpanel" class="tab-pane active" id="openapi">
          <ul class="list-unstyled">
            <li class="level first-level"  v-for="(group,index) in document.docs" :key="index">
              <a
                  class="level-title"
                  href="javascript:void(0)"
                  @click="group.type && group.type !== '' ?handleMenuClick(group.type,group.id,null):''">
                {{ group.name }}</a>
              <ul  v-if="group.child" class="list-unstyled document-list">
                <template  v-for="docs in group.child" >
                  <li class="level second-level">
                    <a
                      :href="docs.child !== undefined && docs.child.length>0?'#'+docs.id:'javascript:void(0)'"
                      class="level-title collapsed side-menu-selector js-switch-icon-btn" role="button"
                       data-toggle="collapse"
                      aria-expanded="false"
                      @click="(!docs.child || docs.child.length===0) ? handleMenuClick(docs.type,docs.id,docs.info):''"
                       :aria-controls="docs.id">
                      {{ docs.name }}
                      <i v-if="docs.child !== undefined && docs.child.length>0"
                         class="fa fa-angle-down switch-icon js-switch-icon" aria-hidden="true"></i>
                    </a>
                    <ul v-if="docs.child !== undefined && docs.child.length>0" :id="docs.id"
                        class="collapse list-unstyled document-list" >
                      <li class="level third-level" v-for="doc in docs.child">
                        <a href="javascript:void(0)"
                           @click="(doc.child === undefined || doc.child.length===0) && doc.info !== undefined ? handleMenuClick(doc.type,doc.id,doc.info):''"
                           class="side-menu-selector js-side-menu-selector"
                           data-tab="FaceLibraryShow">{{ doc.name }}</a>
                      </li>
                    </ul>
                  </li>
                </template>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </aside>
    <main class="documents-content js-documents-content" id="docContent"  style="overflow-y: scroll">
      <template v-if="Type === 'doc'">
        <doc-open-api-info
            :info="info"
        />
      </template>
      <template v-else-if="Type === 'page'">
        <template v-if="page === 'yzInfo'"><doc-yzwl-info/></template>
        <template v-if="page === 'accessGuide'"><doc-must-read/></template>
        <template v-if="page === 'GeneralParam'"><doc-general-param/></template>
      </template>

    </main>
  </div>
</template>

<script>

import docJson from "./web-openapi.js"
import DocOpenApiInfo from "./templates/DocOpenApiInfo.vue"
import DocMustRead from "./templates/DocMustRead"
import DocYzwlInfo from "./templates/DocYzwlInfo"
import DocGeneralParam from "@/views/docs/templates/DocGeneralParam";
export default {
  name: "DocOpenApi",
  components: {
    DocGeneralParam,
    docJson,
    DocOpenApiInfo,
    DocMustRead,
    DocYzwlInfo
  },
  data: function(){
    return {
      Type: "page",
      page: "yzInfo",
      info: null
    }
  },
  mounted: function(){
    let self  = this;
    $(".side-menu-selector").on("click",function () {
      self.onsideMenuSelectorSelected(this)
    });
    let height  = document.body.clientHeight;
    document.getElementById("docMenu").style.height = height + "px";
    document.getElementById("docMenu").style.overflowY = " scroll";
    document.getElementById("docContent").style.height = height + "px";
    document.getElementById("docContent").style.overflowY = " scroll";

    var hash = window.location.hash.replace("#", "");
    if(hash){
      $('a[data-tab="'+ hash +'"]').click();
    }
  },
  created: function(){
    this.document = docJson
  },
  methods :{
    handleMenuClick(type,id,data){
      if (type === "doc" && data){
        this.info = data
        this.Type = type
      }else if (type === "page"){
        this.Type = type
        this.info = null
        this.page = id
      }
    },
    onsideMenuSelectorSelected: function(obj) {
      var self = this;
      $(".side-menu-selector").removeClass('active');
      var tab = $(obj).data('tab');
      if(tab) {
        $(obj).addClass('active');
        $(".js-documents-content>div").scrollTop(0);
      }
    },
  }
}
</script>
<style scoped>
@import "../../assets/css/openapi.css";
</style>