<template>
  <div id="common-content" class="js-article-wrap">
    <ol class="breadcrumb">
      <li class="active">平台介绍</li>
    </ol>
    <article class="article-group">
      <h3 class="article-title">平台概述</h3>
      <div class="dashed-area request-area">
        <p class="ordinary-text">鱼住未来整合身份识别基础服务，以开放API形式向开发者提供动态管理应用及用户标识授权的开放平台。开发者在<a target="_blank"
                                                                                        href="https://console.aidoing.com.cn/">用户控制台</a>创建账号、成为开发者后，可通过阅读本文档接口来帮助开发。如遇问题，可通过<a
            target="_blank" href="https://yzfuture.cn/views/about/index.html">电话、在线咨询、微信客服等多种方式联系我们</a></p>
      </div>
    </article>
    <article class="article-group">
      <h3 class="article-title">身份识别服务</h3>
      <div class="dashed-area request-area">
        <p class="ordinary-text">
          鱼住身份识别服务是基于鱼住云端解码技术，通过移动端NFC功能和PC端读卡器进行证件识读的高效安全解决方案。对二、三代身份证件、港澳台居住证、外国永久居住证、护照、港澳台通行证快速读取等，避免 OCR
          识别模式速度慢，不准确等缺点，使用过程更快捷、更简单、更准确！适用于windows、linux、Android、WEB浏览器、微信小程序等多种操作系统和环境。可广泛应用于实名登记、认证核验等场景。</p>
      </div>
    </article>
    <article class="article-group">
      <h3 class="article-title">SDK及插件</h3>
      <div class="dashed-area request-area">
        <p>我们针对不同的操作系统和环境推出了相应的SDK和插件，方便开发者集成到自己的应用当中。如下：</p>
        <ul class="list-unstyled">
          <li style="margin-top: 10px">
            <a  target="_blank" href="http://d.yzfuture.cn/p/1">证件解码SDK源码包（windows）</a>
          </li>
          <li style="margin-top: 10px">
            <a  target="_blank" href="http://d.yzfuture.cn/p/2">证件解码SDK源码包（linux）</a>
          </li>
          <li style="margin-top: 10px">
            <a  target="_blank" href="http://d.yzfuture.cn/p/3">证件解码SDK源码包（Android）</a>
          </li>
          <li style="margin-top: 10px">
            <a  target="_blank" href="http://d.yzfuture.cn/p/14">二代证读卡多浏览器插件</a>
          </li>
          <li style="margin-top: 10px">
            <a  target="_blank" href="https://mp.weixin.qq.com/wxopen/plugindevdoc?appid=wx0d82ce42bf0f4960">微信小程序插件</a>
          </li>
          <li style="margin-top: 10px">
            <a  target="_blank" href="https://ext.dcloud.net.cn/plugin?id=13400">uniapp 安卓SDK插件</a>
          </li>
        </ul>
        <p class="ordinary-text"></p>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: "DocYzwlInfo"
}
</script>

<style scoped>

</style>
