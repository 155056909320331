<template>
    <div id="vehicle-type-recognition" class="js-article-wrap">
        <ol class="breadcrumb">
            <li><a href="javascript:void(0)">视频结构化</a></li>
            <li class="active">车辆类型识别</li>
        </ol>
        <article class="article-group">
            <h3 class="article-title">简介</h3>
            <p class="ordinary-text">检测上传用户上传的图像资源，分析里面所出现的车辆类型信息。</p>
        </article>
        <article class="article-group">
            <h3 class="article-title">图片要求</h3>
            <p class="ordinary-text">图片格式：JPG(JPEG)，PNG</p>
            <p class="ordinary-text">图片像素尺寸：最小 100*100 像素，最大 4096*4096 像素</p>
            <p class="ordinary-text">图片文件大小：2 MB</p>
        </article>
        <article class="article-group">
            <h3 class="article-title">调用地址</h3>
            <pre class="dashed-area">/video/v1/detectVehicleType</pre>
        </article>
        <article class="article-group">
            <h3 class="article-title">调用方式</h3>
            <pre class="dashed-area">POST</pre>
        </article>
        <article class="article-group">
            <h3 class="article-title">请求参数</h3>
            <table class="table table-area">
                <thead>
                <tr>
                    <th>是否必需</th>
                    <th>参数名</th>
                    <th>类型</th>
                    <th>说明</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>必须</td>
                    <td>appKey</td>
                    <td>String</td>
                    <td>API key</td>
                </tr>
                <tr>
                    <td>必须</td>
                    <td>appSecret</td>
                    <td>String</td>
                    <td>API secret</td>
                </tr>
                <tr>
                    <td rowspan="3">必须（三选一）</td>
                    <td>imageUrl</td>
                    <td>String</td>
                    <td>图片的URL</td>
                </tr>
                <tr>
                    <td>imageData</td>
                    <td>File</td>
                    <td>上传的图片二进制文件。需post multipart/form-data方式</td>
                </tr>
                <tr>
                    <td>imageBase64</td>
                    <td>String</td>
                    <td>Base64编码的二进制图片数据</td>
                </tr>
                <tr>
                    <td>可选（仅供正式API使用）</td>
                    <td>objectRectangle</td>
                    <td>String</td>
                    <td>按指定的位置检测人脸信息。分别为左上角横坐标，左上角纵坐标，宽度，高度。中间用“,”分割。如：“400,300,200,100”</td>
                </tr>
                </tbody>
            </table>
        </article>
        <article class="article-group">
            <h3 class="article-title">返回参数</h3>
            <p>数据格式JSON：</p>
            <table class="table table-area">
                <thead>
                <tr>
                    <th>字段</th>
                    <th>类型</th>
                    <th>说明</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>state</td>
                    <td>String</td>
                    <td>
                        <p>返回结果类型，值分为：</p>
                        <p>SUCCESS:成功</p>
                        <p>FAILURE:失败</p>
                    </td>
                </tr>
                <tr>
                    <td>requestId</td>
                    <td>String</td>
                    <td>每次请求唯一的字符串</td>
                </tr>
                <tr>
                    <td>errorMessage</td>
                    <td>String</td>
                    <td>错误信息，成功返回值为空字符串</td>
                </tr>
                <tr>
                    <td>vehicleTypes</td>
                    <td>Array</td>
                    <td>检测到的车辆类型信息，具体内容见下文</td>
                </tr>
                </tbody>
            </table>

            <p>vehicleTypes数据结构：</p>
            <table class="table table-area">
                <thead>
                <tr>
                    <th>字段</th>
                    <th>类型</th>
                    <th>说明</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>vehicleLocate</td>
                    <td>Object</td>
                    <td>
                        <p>检测到的车辆坐标，包括以下属性，每个属性都为int类型：</p>
                        <ul>
                            <li>left ：左上角横坐标</li>
                            <li>toli：左上角纵坐标</li>
                            <li>width：框宽度</li>
                            <li>height：框高度</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>color</td>
                    <td>String</td>
                    <td>检测分析到的车辆颜色</td>
                </tr>
                <tr>
                    <td>confidence</td>
                    <td>Float</td>
                    <td>
                        <p>对比结果置信度，结果最小为0，最大为100，精确到小数点后三位。数值越大说明检测结果可信度越高</p>
                        <p>返回检测到的最高的置信度</p>
                    </td>
                </tr>
                <tr>
                    <td>type</td>
                    <td>String</td>
                    <td>车辆类型：car/bus</td>
                </tr>
                </tbody>
            </table>

            <p>请求成功返回示例：</p>
            <pre class="dashed-area">
{
    "state": "SUCCESS",
    "errorMessage": "",
    "requestId": " ed376c8e9-46d7-aee6-ef8cd0335-1558c7",
    "vehicleTypes ": [
        {
            "vehicleLocate": {
                "left": 125,
                "top": 211,
                "width": 264,
                "height": 264
            },
            "color": "white",
            "confidence": 0.99752956628799438,
            "type": "car"
        },
        {
            " vehicleLocate ": {
                "left": 125,
                "top": 211,
                "width": 264,
                "height": 264
            },
            "color": "white",
            "confidence": 0.99752956628799438,
            "type": "car"
        }
    ]
}
</pre>

            <p>请求失败返回示例：</p>
            <pre class="dashed-area">
{
    "state": "FAILURE",
    "errorMessage": "ERROR_IMAGE_IS_MUST",
    "requestId": "ed376c8e9-46d7-aee6-ef8cd0335-1558c7"
}</pre>
        </article>
        <article class="article-group">
            <h3 class="article-title">错误信息</h3>
            <table class="table table-area error-table">
                <thead>
                <tr>
                    <th>错误信息</th>
                    <th>说明</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>ERROR_IMAGE_IS_MUST</td>
                    <td>图片信息是必须的</td>
                </tr>
                <tr>
                    <td>ERROR_KEY_ISNOT_LEGAL</td>
                    <td>Key不合法</td>
                </tr>
                <tr>
                    <td>ERROR_SECRET_ISNOT_LEGAL</td>
                    <td>Secret不合法</td>
                </tr>
                </tbody>
            </table>
        </article>
    </div>
</template>

<script>
    export default {
        name: "DocVehicleTypeRecognition"
    }
</script>

<style scoped>

</style>