<template>
  <div>
    <div class="price-banner text-center" style="color: #fff">
      <h1 style="font-size: 55px;padding-top: 40px;padding-bottom: 15px">鱼住云</h1>
      <h1 style="font-size: 45px;padding-bottom: 15px">开发文档</h1>
      <p>适用于多平台，方便快速对接。</p>
    </div>
    <div class="container">
      <div class="row" style="padding-top: 50px">
        <div class="col-xs-3" v-for="(item,index) in docs" :key="index">
          <div class="panel" :class="item.bgColor">
            <div class="panel-heading text-center">
              <h3 class="panel-title">{{ item.title }}</h3>
            </div>
            <div class="panel-body">
              <template v-for="detail in item.details">
                <p class="info-title"><strong>{{ detail.title }}</strong></p>
                <p class="info-body">{{ detail.content }}</p>
              </template>
            </div>
            <div class="panel-footer">
              <a :href="item.url">查看详情</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DocCommon from "@/views/docs/face/DocCommon";
import DocClothing from "./docs/face/DocClothing";
import DocFaceAnalysis from "./docs/face/DocFaceAnalysis";
import DocFaceDetails from "./docs/face/DocFaceDetails";
import DocFaceContrast from "./docs/face/DocFaceContrast";
import DocFaceDetection from "./docs/face/DocFaceDetection";
import DocFaceLibraryDelete from "./docs/face/DocFaceLibraryDelete";
import DocFaceLibraryEdit from "./docs/face/DocFaceLibraryEdit";
import DocFaceLibraryNew from "./docs/face/DocFaceLibraryNew";
import DocFaceLibraryResourceDelete from "./docs/face/DocFaceLibraryResourceDelete";
import DocFaceLibraryResourceNew from "./docs/face/DocFaceLibraryResourceNew";
import DocFaceLibraryResourceShow from "./docs/face/DocFaceLibraryResourceShow";
import DocFaceLibraryShow from "./docs/face/DocFaceLibraryShow";
import DocFaceSearch from "./docs/face/DocFaceSearch";
import DocImageDetection from "./docs/face/DocImageDetection";
import DocLicensePlateRecognition from "./docs/face/DocLicensePlateRecognition";
import DocVehicleTypeRecognition from "./docs/face/DocVehicleTypeRecognition";
import DocVideoDetectionNew from "./docs/face/DocVideoDetectionNew";
import DocVideoDetectionShow from "./docs/face/DocVideoDetectionShow";

export default {
  name: "doc",
  data: function () {
    return {
      Type: "Common",
      docs: [
        {
          title: "WEB 开放API",
          bgColor: "panel-primary",
          url: "/openapi.html",
          details: {
            info: {title: "简介", content: "为开发者提供动态管理应用及用户标识的接口"},
            scenario: {title: "适用场景", content: "开发者需要快速管理应用及用户标识的场景"},
            functional: {title: "主要功能", content: "应用管理，用户标识管理（查询、创建、删除、服务授权）等"},
          }
        },
        {
          title: "NFC Windows接入文档",
          bgColor: "panel-success",
          url: "",
          details: {
            info: {title: "API简介", content: "平台与平台之间的对接"},
            supports: {title: "支持那些语言？调用方式是什么？", content: ""},
            scenario: {title: "适用场景", content: "需要接入开放平台并适合动态操作"},
            functional: {title: "包含功能", content: "应用查询，用户标识查询，创建用户标识，用户标识充值"},
          }
        },
        {
          title: "NFC Android接入文档",
          bgColor: "panel-info",
          url: "",
          details: {
            info: {title: "API简介", content: "平台与平台之间的对接"},
            supports: {title: "支持那些语言？调用方式是什么？", content: ""},
            scenario: {title: "适用场景", content: "需要接入开放平台并适合动态操作"},
            functional: {title: "包含功能", content: "应用查询，用户标识查询，创建用户标识，用户标识充值"},
          }
        },
        {
          title: "NFC 微信小程序接入文档",
          bgColor: "panel-warning",
          url: "",
          details: {
            info: {title: "API简介", content: "平台与平台之间的对接"},
            supports: {title: "支持那些语言？调用方式是什么？", content: ""},
            scenario: {title: "适用场景", content: "需要接入开放平台并适合动态操作"},
            functional: {title: "包含功能", content: "应用查询，用户标识查询，创建用户标识，用户标识充值"},
          }
        }
      ],
    }
  },
  mounted: function () {
    let self = this;
    $(".side-menu-selector").on("click", function () {
      self.onsideMenuSelectorSelected(this)
    });
    let height = document.body.clientHeight;
    document.getElementById("docMenu").style.height = height + "px";
    document.getElementById("docMenu").style.overflowY = " scroll";
    document.getElementById("docContent").style.height = height + "px";
    document.getElementById("docContent").style.overflowY = " scroll";

    var hash = window.location.hash.replace("#", "");
    console.log(hash);
    if (hash) {
      $('a[data-tab="' + hash + '"]').click();
    }
  },
  created: function () {
    console.log(document.body.clientHeight);
  },
  components: {
    DocCommon
    , DocClothing
    , DocFaceAnalysis
    , DocFaceDetails
    , DocFaceDetection
    , DocFaceContrast
    , DocFaceLibraryDelete
    , DocFaceLibraryEdit
    , DocFaceLibraryNew
    , DocFaceLibraryResourceDelete
    , DocFaceLibraryResourceNew
    , DocFaceLibraryResourceShow
    , DocFaceLibraryShow
    , DocFaceSearch
    , DocImageDetection
    , DocLicensePlateRecognition
    , DocVehicleTypeRecognition
    , DocVideoDetectionNew
    , DocVideoDetectionShow
  },
  methods: {
    onsideMenuSelectorSelected: function (obj) {
      var self = this;
      $(".side-menu-selector").removeClass('active');
      var tab = $(obj).data('tab');
      if (tab) {
        $(obj).addClass('active');
        self.Type = tab;
        $(".js-documents-content>div").scrollTop(0);
      }
    },
  }
}
</script>
<style scoped>
@import "../assets/css/doc.css";
</style>