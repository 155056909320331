<template>
    <div>
        <TechnicalHeader
                icon="discernment_cid_icon"
                name="CID公民网络身份识别验证技术"
                title="公民网络身份识别验证。"
                domId=""
                :hideDoc=true
        />

        <main>
            <div class="technology-area small-technology-area">
                <div class="technology-content">
                    <div class="describe-image-area">
                        <img src="../../../src/assets/images/discernment_cid_describe.png">
                    </div>
                    <div class="technology-describe-area">
                        <p class="technology-describe-title">技术描述</p>
                        <p class="technology-describe-text">通过用户身份证件NFC读取及云解芯片信息，以及人脸识别验证，可广泛用于电子政务、电子商务等人证识别服务场景。</p>
                        <div class="technology-line"></div>
                    </div>
                </div>
            </div>

            <div class="technology-area">
                <div class="technology-content">
                    <p class="technology-scene-title">应用场景</p>
                    <ul class="technology-scene-list">
                        <li data-code="0" class="active">
                            <span>网络安全交易</span>
                            <div class="scene-line"></div>
                        </li>
                        <li data-code="1">
                            <span>快递收件</span>
                            <div class="scene-line"></div>
                        </li>
                    </ul>

                    <div class="scene-image-area">
                        <img src="../../../src/assets/images/discernment_cid_scene1.png">
                        <img src="../../../src/assets/images/discernment_cid_scene2.png">
                    </div>
                </div>
            </div>

            <div class="technology-area technology-buy-area">
                <div class="technology-content">
                    <p class="technology-buy-title">即刻拥有鱼住CID公民网络身份识别验证技术</p>
                    <p class="technology-buy-text">享受更高的性价比和技术优势</p>
                    <a href="price.html" class="buy-btn">立即购买</a>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import TechnicalHeader from '@/components/TechnicalHeader.vue';
    export default {
        name: "DiscernmentCid",
        data: function () {
            return {
                code: 0
            };
        },
        mounted: function () {
            let self = this;
            $(".technology-scene-list li").on("click", function () {
                let code = $(this).data("code");
                if (code != self.code) {
                    $(".technology-scene-list li.active").removeClass("active");
                    $(this).addClass("active");
                    $(".scene-image-area").find("img").fadeOut(500).eq(code).fadeIn(500);
                    self.code = code
                }
            })
        },
        components: {
            TechnicalHeader
        }
    }
</script>

<style scoped>
    @import "../../assets/css/face_common.css";
    .technology-title-area{
        background: url(../../assets/images/discernment_cid_bg.png) center no-repeat;
        background-size: cover;
    }
    .technology-scene-list{
        width: 400px;
    }
</style>