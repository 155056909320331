<template>
    <div class="technology-title-area">
        <div class="technology-title-content">
            <div class="technology-title">
                <i class="face-icon" :class="icon"></i>
                <span>{{name}}</span>
            </div>
            <p class="technology-title-details">{{title}}</p>
            <div class="title-button-area">
<!--                <a class="title-button title-button-primary" href="price.html" data-locale="freeExperience">免费体验</a>-->
<!--                <a class="title-button title-button-default" href="price.html" data-locale="businessCooperation">商务合作</a>-->
<!--                <a v-show="!hideDoc" class="title-button title-button-default" :href="'documents.html#'+domId" data-locale="technologyDocument">技术文档</a>-->
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "TechnicalHeader",
        props: {
            icon: String,
            name: String,
            title: String,
            domId: String,
            hideDoc: Boolean
        }
    }
</script>