<template>
    <div id="face-analysis" class="js-article-wrap">
        <ol class="breadcrumb">
            <li><a href="javascript:void(0)">人脸识别</a></li>
            <li><a href="javascript:void(0)">人脸</a></li>
            <li class="active">人脸分析详情</li>
        </ol>
        <article class="article-group">
            <h3 class="article-title">简介</h3>
            <p class="ordinary-text">通过检测出的人脸标识objectToken，分析关键点，人脸属性等相关信息。</p>
        </article>
        <article class="article-group">
            <h3 class="article-title">调用地址</h3>
            <pre class="dashed-area">/face/v1/analyzeByToken</pre>
        </article>
        <article class="article-group">
            <h3 class="article-title">调用方式</h3>
            <pre class="dashed-area">POST</pre>
        </article>
        <article class="article-group">
            <h3 class="article-title">请求参数</h3>
            <table class="table table-area">
                <thead>
                <tr>
                    <th>是否必需</th>
                    <th>参数名</th>
                    <th>类型</th>
                    <th>说明</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>必须</td>
                    <td>appKey</td>
                    <td>String</td>
                    <td>API key</td>
                </tr>
                <tr>
                    <td>必须</td>
                    <td>appSecret</td>
                    <td>String</td>
                    <td>API secret</td>
                </tr>
                <tr>
                    <td>必须</td>
                    <td>objectToken</td>
                    <td>String</td>
                    <td>人脸标识</td>
                </tr>
                <tr>
                    <td>可选</td>
                    <td>returnType</td>
                    <td>int</td>
                    <td>
                        <p>返回类型：</p>
                        <p>0：返回人脸矩形框位置</p>
                        <p>1：返回人脸关键点</p>
                        <p>2：返回检测到的人脸属性</p>
                        <p>3：返回所有信息</p>
                        <p>参数默认为0</p>
                    </td>
                </tr>
                </tbody>
            </table>
        </article>
        <article class="article-group">
            <h3 class="article-title">返回参数</h3>
            <p>数据格式JSON：</p>
            <table class="table table-area">
                <thead>
                <tr>
                    <th>字段</th>
                    <th>类型</th>
                    <th>说明</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>state</td>
                    <td>String</td>
                    <td>
                        <p>返回结果类型，值分为：</p>
                        <p>SUCCESS:成功</p>
                        <p>FAILURE:失败</p>
                    </td>
                </tr>
                <tr>
                    <td>errorMessage</td>
                    <td>String</td>
                    <td>错误信息，成功返回值为空字符串</td>
                </tr>
                <tr>
                    <td>objectToken</td>
                    <td>String</td>
                    <td>返回的人脸唯一标识符，临时缓存48小时，存入人脸库中可以长久保存。</td>
                </tr>
                <tr>
                    <td>face</td>
                    <td>Object</td>
                    <td>检测到的人脸详细信息，具体内容见下文</td>
                </tr>
                <tr>
                    <td>requestId</td>
                    <td>String</td>
                    <td>每次请求唯一的字符串</td>
                </tr>
                </tbody>
            </table>

            <p>face数据结构：</p>
            <table class="table table-area">
                <thead>
                <tr>
                    <th>字段</th>
                    <th>类型</th>
                    <th>说明</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>faceLocate</td>
                    <td>Object</td>
                    <td>
                        <p>人脸框定位坐标，包括下面属性，每个属性都为int类型：</p>
                        <ul>
                            <li>left ：人脸框左上角横坐标</li>
                            <li>toli：人脸框左上角纵坐标</li>
                            <li>width：人脸框宽度</li>
                            <li>height：人脸框高度</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>attributes</td>
                    <td>Object</td>
                    <td>
                        <p>检测到人脸属性：如</p>
                        <ul>
                            <li>gender(String)：male(男性)，female(女性)</li>
                            <li>age(int)：年龄</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>landmark</td>
                    <td>Array</td>
                    <td>人脸关键点坐标数组</td>
                </tr>
                </tbody>
            </table>

            <p>请求成功返回示例：</p>
            <pre class="dashed-area">
{
    "state": "SUCCESS",
    "errorMessage": "",
    "requestId": " ed376c8e9-46d7-aee6-ef8cd0335-1558c7",
    "objectToken": "c0ZDBmZjMwNDBhODMzMzQ4ZDM="
    "face": {
        "faceLocate": {
            "left": 133,
            "top": 215,
            "width": 260,
            "height": 260
        },
        "attributes": {
            "gender": "Male",
            "age": 18
        },
        "landmark": [
            {
                "x": 123,
                "y": 23
            },
            {
                "x": 123,
                "y": 23
            }
        ]
    }
}

</pre>

            <p>请求失败返回示例：</p>
            <pre class="dashed-area">
{
    "state": "FAILURE",
    "errorMessage": "ERROR_OBJECT_TOKEN_ISNOT_FOUND ",
    "requestId": "ed376c8e9-46d7-aee6-ef8cd0335-1558c7"
}</pre>
        </article>
        <article class="article-group">
            <h3 class="article-title">错误信息</h3>
            <table class="table table-area error-table">
                <thead>
                <tr>
                    <th>错误信息</th>
                    <th>说明</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>ERROR_OBJECT_TOKEN_ISNOT_FOUND</td>
                    <td>人脸标识不存在</td>
                </tr>
                <tr>
                    <td>ERROR_KEY_ISNOT_LEGAL</td>
                    <td>Key不合法</td>
                </tr>
                <tr>
                    <td>ERROR_SECRET_ISNOT_LEGAL</td>
                    <td>Secret不合法</td>
                </tr>
                </tbody>
            </table>
        </article>
    </div>
</template>

<script>
    export default {
        name: "DocFaceAnalysis"
    }
</script>

<style scoped>

</style>