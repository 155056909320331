<template>
    <div id="video-detection-show" class="js-article-wrap">
        <ol class="breadcrumb">
            <li><a href="javascript:void(0)">视频结构化</a></li>
            <li><a href="javascript:void(0)">对象检测</a></li>
            <li><a href="javascript:void(0)">视频检测</a></li>
            <li class="active">资源分析</li>
        </ol>
        <article class="article-group">
            <h3 class="article-title">简介</h3>
            <p class="ordinary-text">分析用户的视频资源。</p>
        </article>
        <article class="article-group">
            <h3 class="article-title">视频要求</h3>
            <p class="ordinary-text">1、时长在1小时以内</p>
            <p class="ordinary-text">2、分辨率需要大于等于320*240，小于等于2560*1440</p>
        </article>
        <article class="article-group">
            <h3 class="article-title">调用地址</h3>
            <pre class="dashed-area">/video/v1/analyzeMedia</pre>
        </article>
        <article class="article-group">
            <h3 class="article-title">调用方式</h3>
            <pre class="dashed-area">POST</pre>
        </article>
        <article class="article-group">
            <h3 class="article-title">请求参数</h3>
            <table class="table table-area">
                <thead>
                <tr>
                    <th>是否必需</th>
                    <th>参数名</th>
                    <th>类型</th>
                    <th>说明</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>必须</td>
                    <td>appKey</td>
                    <td>String</td>
                    <td>API key</td>
                </tr>
                <tr>
                    <td>必须</td>
                    <td>appSecret</td>
                    <td>String</td>
                    <td>API secret</td>
                </tr>
                <tr>
                    <td>必须</td>
                    <td>videoSurce</td>
                    <td>String</td>
                    <td>基于http、https、或者rtmp流的资源</td>
                </tr>
                <tr>
                    <td>选填</td>
                    <td>videoAuth</td>
                    <td>String</td>
                    <td>String 资源的用户验证</td>
                </tr>
                </tbody>
            </table>
        </article>
        <article class="article-group">
            <h3 class="article-title">返回参数</h3>
            <p>同步返回及异步回调结果</p>
            <p>数据格式JSON：</p>
            <table class="table table-area">
                <thead>
                <tr>
                    <th>字段</th>
                    <th>类型</th>
                    <th>说明</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>state</td>
                    <td>String</td>
                    <td>
                        <p>返回结果类型，值分为：</p>
                        <p>SUCCESS:成功</p>
                        <p>FAILURE:失败</p>
                    </td>
                </tr>
                <tr>
                    <td>requestId</td>
                    <td>String</td>
                    <td>每次请求唯一的字符串</td>
                </tr>
                <tr>
                    <td>errorMessage</td>
                    <td>String</td>
                    <td>错误信息，成功返回值为空字符串</td>
                </tr>
                <tr>
                    <td>resultToken</td>
                    <td>String</td>
                    <td>上传资源的路径</td>
                </tr>
                </tbody>
            </table>

            <p>请求成功返回示例：</p>
            <pre class="dashed-area">
{
    "state": "SUCCESS",
    "errorMessage": "",
    "requestId": "ed376c8e9-46d7-aee6-ef8cd0335-1558c7",
    "sourceUrl": "https://host/objectName.mp4"
}</pre>

            <p>请求失败返回示例：</p>
            <pre class="dashed-area">
{
    "state": "FAILURE",
    "errorMessage": "ERROR_SOURCE_IS_MUST",
    "requestId": "ed376c8e9-46d7-aee6-ef8cd0335-1558c7"
}</pre>
        </article>
        <article class="article-group">
            <h3 class="article-title">错误信息</h3>
            <table class="table table-area error-table">
                <thead>
                <tr>
                    <th>错误信息</th>
                    <th>说明</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>ERROR_KEY_ISNOT_LEGAL</td>
                    <td>Key不合法</td>
                </tr>
                <tr>
                    <td>ERROR_SECRET_ISNOT_LEGAL</td>
                    <td>Secret不合法</td>
                </tr>
                <tr>
                    <td>ERROR_SOURCE_FAILED</td>
                    <td>获取资源失败</td>
                </tr>
                <tr>
                    <td>ERROR_EMPTY_RESULT</td>
                    <td>检测结果为空</td>
                </tr>
                </tbody>
            </table>
        </article>
    </div>
</template>

<script>
    export default {
        name: "DocVideoDetectionShow"
    }
</script>

<style scoped>

</style>