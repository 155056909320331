<template>
    <div>
        <TechnicalHeader
                icon="discernment_gender_icon"
                name="性别识别技术"
                title="分析视频（图像）中人的性别特征。"
                domId="FaceDetection"
        />
        <main>
            <div class="technology-area small-technology-area">
                <div class="technology-content">
                    <div class="describe-image-area">
                        <img src="../../assets/images/discernment_gender_describe.png">
                    </div>
                    <div class="technology-describe-area">
                        <p class="technology-describe-title">技术描述</p>
                        <p class="technology-describe-text">分析给定的视频（图像），获取其中的人体特征信息，并返回人体性别属性。通过结果可以有效的辨识出男女特征。基于深度神经网络实现的快速、精准、广泛的人体特征识别系统。</p>
                        <div class="technology-line"></div>
                    </div>
                </div>
            </div>

            <div class="technology-area">
                <div class="technology-content">
                    <p class="technology-scene-title">应用场景</p>
                    <ul class="technology-scene-list">
                        <li data-code="0" class="active">
                            <span>人员分类统计</span>
                            <div class="scene-line"></div>
                        </li>
                        <li data-code="1">
                            <span>定向广告推荐</span>
                            <div class="scene-line"></div>
                        </li>
                    </ul>

                    <div class="scene-image-area">
                        <img src="../../assets/images/discernment_gender_scene1.png">
                        <img src="../../assets/images/discernment_gender_scene2.png">
                    </div>
                </div>
            </div>

            <div class="technology-area technology-buy-area">
                <div class="technology-content">
                    <p class="technology-buy-title">即刻拥有鱼住性别识别技术</p>
                    <p class="technology-buy-text">享受更高的性价比和技术优势</p>
                    <a href="price.html" class="buy-btn">立即购买</a>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    import TechnicalHeader from '@/components/TechnicalHeader.vue';
    export default {
        name: "DiscernmentGender",
        data: function () {
            return {
                code: 0
            };
        },
        mounted: function () {
            let self = this;
            $(".technology-scene-list li").on("click", function () {
                let code = $(this).data("code");
                if (code != self.code) {
                    $(".technology-scene-list li.active").removeClass("active");
                    $(this).addClass("active");
                    $(".scene-image-area").find("img").fadeOut(500).eq(code).fadeIn(500);
                    self.code = code
                }
            })
        },
        components: {
            TechnicalHeader
        }
    }
</script>

<style scoped>
    @import "../../assets/css/face_common.css";
    .technology-title-area{
        background: url(../../assets/images/discernment_gender_bg.png) center no-repeat;
        background-size: cover;
    }
    .technology-scene-list{
        width: 400px;
    }
    .describe-image-area{
        padding-top: 120px;
    }
</style>