const docJson = {
  "title": "WEB开放接口文档（测试版）",
  "docs": [
    {
      "name": "平台介绍",
      "type":"page",
      "id":"yzInfo",
      "child": null
    },
    {
      "name": "接入指南",
      "type":"page",
      "id":"accessGuide"
    },
    {
      "name": "通用说明",
      "type":"page",
      "id":"GeneralParam",
    },
    {
      "name": "API",
      "type":"",
      "id":"",
      "child": [
        {
          "name": "获取AccessToken",
          "id": "GetToken",
          "type":"doc",
          "info": {
            "method": "GET",
            "path": ["API","获取AccessToken"],
            "url": "/openapi/nfc/v1/gettoken",
            "description": "accessToken 是全局唯一调用接口凭自己，调用每个接口时都需要accessToken,开发者需妥善保存。accessToken有效期为1个小时，重复获取将导致上次获取的accessToken失效",
            "params": [
              {"name": "developKey","position": "path","required": true,"type": "String","desc": "开发者key,从用户控制台获取"},
              {"name": "developSecret","position": "path","required": true,"type": "String","desc": "开发者密钥,从用户控制台获取"}
            ],
            "response": [
              {"name": "accessToken","type": "String","desc": "开发者key,从用户控制台获取"},
              {"name": "expireIn","type": "Integer","desc": "过期时间"}
            ],
            "requestDemo": {
              "code": 0,
              "message": "成功",
              "data": {
                "accessToken": "7d912e0b8715bbb8c21e51ed846b0e2c"
              },
              "collection": []
            },
            "responseDemo": {
              "code": 0,
              "message": "成功",
              "data": {
                "expireIn": 3600,
                "accessToken": "5f28fe91196c9c56e95320c57763ba30"
              },
              "collection": []
            },
            "responseErrorDemo": null
          }
        },
        {
          "name": "查询账户余额",
          "id": "Account",
          "type":"doc",
          "info": {
            "method": "GET",
            "path": ["API","查询账户余额"],
            "url": "/openapi/nfc/v1/account",
            "description": "获取开发者账户余额剩余情况及NFC次数剩余数量",
            "params": [
              {"name": "accessToken","position": "path","required": true,"type": "String","desc": "调用获取AccessToken接口返回的参数"},
            ],
            "response": [
              {"name": "balance","type": "Float","desc": "开发者账户所剩余额"},
              {"name": "nfcNumbers","type": "Integer","desc": "开发者NFC账户剩余次数"}
            ],
            "requestDemo": null,
            "responseDemo": {
              "code": 0,
              "message": "成功",
              "data": {
                "balance": 113.55,
                "nfcNumbers": 999839
              },
              "collection": []
            },
            "responseErrorDemo": null
          }
        },
        {
          "id":"apps",
          "name": "应用管理",
          "type": "",
          "child": [
            {
              "name": "应用列表查询",
              "id": "appList",
              "type": "doc",
              "info": {
                "method": "GET",
                "path": ["API","应用管理","应用列表查询"],
                "url": "/openapi/nfc/v1/app/list",
                "description": "开发者获取当前所有应用信息，包含开发需要用到的appKey和appSecret，注意：返回数据为数组，因此在“collection”字段中",
                "params": [
                  {"name": "accessToken","position": "path","required": true,"type": "String","desc": "调用获取AccessToken接口返回的参数"},
                ],
                "response": [
                  {"name": "name","type": "String","desc": "应用名称"},
                  {"name": "appKey","type": "String","desc": "应用Key,用于SDK调用证件读取服务及API查询操作"},
                  {"name": "appSecret","type": "String","desc": "应用密钥,用于SDK调用证件读取服务"},
                  {"name": "leader","type": "String","desc": "应用负责人"},
                  {"name": "leaderEmail","type": "String","desc": "应用负责人邮箱，有问题优先发送邮件"},
                  {"name": "leaderPhone","type": "String","desc": "应用负责人电话"},
                  {"name": "nfcNumbers","type": "Integer","desc": "应用剩余次数"},
                  {"name": "remark","type": "String","desc": "备注"},
                  {"name": "state","type": "String","desc": "状态"},
                  {"name": "createdAt","type": "String","desc": "创建时间"},
                ],
                "requestDemo": null,
                "responseDemo": {
                  "code": 0,
                  "message": "成功",
                  "data": null,
                  "collection": [
                    {
                      "name": "小程序开发",
                      "appKey": "7F5FE0E40FC998DD63AEB65AB165C36E",
                      "appSecret": "NTdlYzFhZmRlYjQ5ZjA4NDhkMTFlOTk3MjZhZTk5ZmQ=",
                      "leader": "M先生",
                      "leaderEmail": "8888@qq.com",
                      "leaderPhone": "181****5656",
                      "nfcNumbers": 1,
                      "remark": null,
                      "state": "1",
                      "createdAt": "2020-11-06 17:25:04",
                    },
                    {
                      "name": "最新的APP",
                      "appKey": "662CD00E7524669B6ACFA180074D57EF",
                      "appSecret": "Y2QwYjE5Njg2MWM0NGU5Mjg5Y2U1MDZiMWMwNzNlMjA=",
                      "leader": "H先生",
                      "leaderEmail": "1234@qq.com",
                      "leaderPhone": "138****0022",
                      "nfcNumbers": 10,
                      "remark": "这里是备注信息",
                      "state": "1",
                      "createdAt": "2021-06-28 10:25:46",
                    },
                  ]
                }
              }
            },
            {
              "name": "删除应用",
              "id": "removeApp",
              "type": "doc",
              "info": {
                "method": "POST",
                "path": ["API","应用管理","删除应用"],
                "url": "/openapi/nfc/v1/app/delete",
                "description": "删除一个NFC应用。操作成功后，集成该应用的程序将不能调用服务，请谨慎使用",
                "params": [
                  {"name": "accessToken","position": "path","required": true,"type": "String","desc": "调用获取AccessToken接口返回的参数"},
                  {"name": "appKey","position": "path","required": true,"type": "String","desc": "应用的appKey"},
                ],
                "response": [
                  {"name": "code","type": "String","desc": "状态码，成功为0, 其余为错误码，具体详情请查看错误码说明章节"},
                  {"name": "message","type": "String","desc": "状态信息，返回错误详情"}
                ],
                "requestDemo": null,
                "responseDemo":{
                  "code": 0,
                  "message": "成功",
                  "data": null,
                  "collection": []
                },
                "responseErrorDemo":{
                  "code": 50001,
                  "message": "NFC appKey 错误",
                  "data": null,
                  "collection": []
                }
              }
            },
            {
              "name": "应用次数授权",
              "id": "appNumbers",
              "type": "doc",
              "info": {
                "method": "POST",
                "path": ["API","应用管理","应用次数授权"],
                "url": "/openapi/nfc/v1/app/recharge",
                "description": "开发者对当前【共享计费】模式的应用进行次数授权，授权的次数将从开发者【NFC次数账户】扣除，操作成功后，将授权次数添加给该应用",
                "params": [
                  {"name": "accessToken","position": "path","required": true,"type": "String","desc": "调用获取AccessToken接口返回的参数"},
                  {"name": "appKey","position": "body","required": true,"type": "String","desc": "应用的appKey"},
                  {"name": "amount","position": "body","required": true,"type": "Integer","desc": "需要授权的次数"},
                ],
                "response": [
                  {"name": "numbers","type": "Integer","desc": "授权后应用剩余次数"},
                  {"name": "serialNo","type": "String","desc": "系统流水号，如遇问题可将此号提供给管理员"},
                ],
                "requestDemo": {
                  "amount": 100,
                  "appKey": "662CD00E7524669B6ACFA180074D57EF"
                },
                "responseDemo": {
                  "code": 0,
                  "message": "成功",
                  "data": {
                    "numbers": 100,
                    "serial_no": "S1_2facf17c-8f6c-4932-b638-601c7800ae38"
                  },
                  "collection": []
                }
              }
            }
          ]
        },
        {
          "id":"userIds",
          "name": "用户标识管理",
          "type": "",
          "child":[
            {
              "name": "用户标识列表查询",
              "id": "userIdList",
              "type": "doc",
              "info": {
                "method": "GET",
                "path": ["API","用户标识管理","用户标识列表查询"],
                "url": "/openapi/nfc/v1/userId/list",
                "description": "获取应用下所有用户标识（包含已删除的）,返回值“data”为分页数据，“collection”为列表数据",
                "params": [
                  {"name": "accessToken","position": "path","required": true,"type": "String","desc": "调用获取AccessToken接口返回的参数"},
                  {"name": "appKey","position": "path","required": true,"type": "String","desc": "应用的appKey"},
                  {"name": "page","position": "path","required": true,"type": "Integer","desc": "当前页,最小为1"},
                  {"name": "perPage","position": "path","required": true,"type": "Integer","desc": "分页最大数量，1~500之间"},
                ],
                "response": [
                  {"name": "count","type": "Integer","desc": "返回值body集合中，当前应用下用户标识总量"},
                  {"name": "page","type": "Integer","desc": "返回值body集合中，当前查询页码"},
                  {"name": "perPage","type": "Integer","desc": "返回值body集合中，分页最大数量"},
                  {"name": "userId","type": "String","desc": "返回值collection集合中，用户标识"},
                  {"name": "deleted","type": "String","desc": "返回值collection集合中，是否已删除，'0'否，'1'是"},
                  {"name": "endAt","type": "String","desc": "返回值collection集合中，授权到期时间"},
                  {"name": "hasBind","type": "String","desc": "返回值collection集合中，是否已绑定终端,返回字符串"},
                  {"name": "isBind","type": "Boolean","desc": "返回值collection集合中，是否已绑定终端，true 已绑定，false 未绑定"},
                  {"name": "bindAt","type": "String","desc": "返回值collection集合中，终端绑定时间（时间格式为：yyyy-MM-dd HH:mm:ss，需要注意刚生成未使用为空字符串）"},
                  {"name": "isDurable","type": "Boolean","desc": "返回值collection集合中，是否永久授权"},
                  {"name": "numbers","type": "Integer","desc": "返回值collection集合中，标识剩余次数"},
                  {"name": "remark","type": "String","desc": "返回值collection集合中，备注信息"},
                ],
                "requestDemo": {
                  url:"openapi/nfc/v1/userId/list?accessToken=51b7b3172f5da22845be93728aca1054&appKey=6029E8D5B10B443F7EAB9E9C08B77CE3&page=1&perPage=100",
                  demo: null
                },
                "responseDemo":{
                  "code": 0,
                  "message": "成功",
                  "data": {
                    "perPage": 100,
                    "count": 1,
                    "page": 1
                  },
                  "collection": [
                    {
                      "bindAt": "2021-11-12 10:14:21",
                      "isBind": true,
                      "deleted": "0",
                      "endAt": "2021-10-21 16:55:13",
                      "hasBind": "已绑定",
                      "isDurable": false,
                      "numbers": 0,
                      "remark": "",
                      "userId": "test01abcdwfasdfalsdkfjeoi3234235"
                    }
                  ]
                },
                "responseErrorDemo":{
                  "code": 50001,
                  "message": "NFC appKey 错误",
                  "data": null,
                  "collection": []
                }
              }
            },
            {
              "name": "用户标识新增",
              "id": "createUserId",
              "type": "doc",
              "info": {
                "method": "POST",
                "path": ["API","用户标识管理","用户标识新增"],
                "url": "/openapi/nfc/v1/userId/add",
                "description": "为当前应用添加一个用户标识，此标识为用户调用身份认证服务的授权凭证，同一应用下用户标识唯一（同一标识可用于不同应用）。",
                "params": [
                  {"name": "accessToken","position": "path","required": true,"type": "String","desc": "调用获取AccessToken接口返回的参数"},
                  {"name": "appKey","position": "body","required": true,"type": "String","desc": "应用的appKey"},
                  {"name": "userId","position": "body","required": true,"type": "String","desc": "用户标识，当前appKey下唯一，由数字、字母组成，长度为3-50之间"},
                  {"name": "remark","position": "body","required": false,"type": "String","desc": "备注信息，长度为0~100之间"},
                ],
                "response": [
                  {"name": "code","type": "String","desc": "状态码，成功为0, 其余为错误码，具体详情请查看错误码说明章节"},
                  {"name": "message","type": "String","desc": "状态信息，返回错误详情"}
                ],
                "requestDemo": {
                  url:"openapi/nfc/v1/userData/add?accessToken=2cf97eb510b8e7c1f4ea8d083a91901b",
                  demo: {
                    "appKey": "6029E8D5B10B443F7EAB9E9C08B77CE3",
                    "userId": "1001",
                    "remark": "备注信息",
                  }
                },
                "responseDemo":{
                  "code": 0,
                  "message": "成功",
                  "data": null,
                  "collection": []
                },
                "responseErrorDemo":{
                  "code": 50001,
                  "message": "NFC appKey 错误",
                  "data": null,
                  "collection": []
                }
              }
            },
            {
              "name": "用户标识删除",
              "id": "removeUserId",
              "type": "doc",
              "info": {
                "method": "POST",
                "path": ["API","用户标识管理","用户标识删除"],
                "url": "/openapi/nfc/v1/userId/delete",
                "description": "删除应用下的用户标识，删除后该标识在当前应用中的相关身份验证服务不可用。（可通过添加用户标识接口重新添加）",
                "params": [
                  {"name": "accessToken","position": "path","required": true,"type": "String","desc": "调用获取AccessToken接口返回的参数"},
                  {"name": "appKey","position": "body","required": true,"type": "String","desc": "应用的appKey"},
                  {"name": "userId","position": "body","required": true,"type": "String","desc": "用户标识"},
                ],
                "response": [
                  {"name": "code","type": "String","desc": "状态码，成功为0, 其余为错误码，具体详情请查看错误码说明章节"},
                  {"name": "message","type": "String","desc": "状态信息，返回错误详情"}
                ],
                "requestDemo": {
                  url:"/openapi/nfc/v1/userId/delete?accessToken=391771009d6a7e199a289c5abd367619",
                  demo: {
                    "appKey": "6029E8D5B10B443F7EAB9E9C08B77CE3",
                    "userId": "1001"
                  }
                },
                "responseDemo":{
                  "code": 0,
                  "message": "成功",
                  "data": null,
                  "collection": []
                },
                "responseErrorDemo":{
                  "code": 50001,
                  "message": "NFC appKey 错误",
                  "data": null,
                  "collection": []
                }
              }
            },
            {
              "name": "用户标识是否存在",
              "id": "existsUserId",
              "type": "doc",
              "info": {
                "method": "GET",
                "path": ["API","用户标识管理","用户标识是否存在"],
                "url": "/openapi/nfc/v1/userId/exists",
                "description": "用于查询用户标识是否存在于当前应用中（已删除的用户表示为false）",
                "params": [
                  {"name": "accessToken","position": "path","required": true,"type": "String","desc": "调用获取AccessToken接口返回的参数"},
                  {"name": "appKey","position": "path","required": true,"type": "String","desc": "应用的appKey"},
                  {"name": "userId","position": "path","required": true,"type": "String","desc": "用户标识"},
                ],
                "response": [
                  {"name": "exists","type": "Boolean","desc": "true已存在，false不存在"},
                ],
                "requestDemo": {
                  url:"/openapi/nfc/v1/userId/exists?accessToken=51b7b3172f5da22845be93728aca1054&appKey=6029E8D5B10B443F7EAB9E9C08B77CE4&userId=1001",
                },
                "responseDemo":{
                  "code": 0,
                  "message": "成功",
                  "data": {
                    "exists": true
                  },
                  "collection": []
                },
                "responseErrorDemo":{
                  "code": 50001,
                  "message": "NFC appKey 错误",
                  "data": null,
                  "collection": []
                }
              }
            },
            {
              "name": "用户标识详情及授权查询",
              "id": "numbers",
              "type": "doc",
              "info": {
                "method": "GET",
                "path": ["API","用户标识管理","用户标识详情及授权查询"],
                "url": "/openapi/nfc/v1/userId/numbers",
                "description": "获取用户标识详情及授权内容。包含是否绑定终端、绑定终端时间、授权次数、授权到期时间、是否永久授权。（永久授权情况下，授权次数为0次，到期时间为空字符串）",
                "params": [
                  {"name": "accessToken","position": "path","required": true,"type": "String","desc": "调用获取AccessToken接口返回的参数"},
                  {"name": "appKey","position": "path","required": true,"type": "String","desc": "应用的appKey"},
                  {"name": "userId","position": "path","required": true,"type": "String","desc": "用户标识"},
                ],
                "response": [
                  {"name": "hasBind","type": "Boolean","desc": "是否绑定终端，true：已绑定"},
                  {"name": "bindAt","type": "String","desc": "终端绑定时间（时间格式为：yyyy-MM-dd HH:mm:ss，需要注意刚生成未使用为空字符串）"},
                  {"name": "numbers","type": "Integer","desc": "剩余授权次数"},
                  {"name": "endAt","type": "String","desc": "授权到期时间（时间格式为：yyyy-MM-dd HH:mm:ss）"},
                  {"name": "isDurable","type": "Boolean","desc": "是否为永久授权，true 永久授权，false 非永久授权"}
                ],
                "requestDemo": {
                  url:"/openapi/nfc/v1/userId/numbers?accessToken=51b7b3172f5da22845be93728aca1054&appKey=6029E8D5B10B443F7EAB9E9C08B77CE3&userId=1001\n",
                },
                "responseDemo":{
                  "code": 0,
                  "message": "成功",
                  "data": {
                    "bindAt": "2021-08-07 16:07:41",
                    "hasBind": true,
                    "endAt": "",
                    "isDurable": false,
                    "numbers": 0
                  },
                  "collection": []
                },
                "responseErrorDemo":{
                  "code": 50005,
                  "message": "用户标识错误",
                  "data": null,
                  "collection": []
                }
              }
            },
            {
              "name": "用户标识授权",
              "id": "license",
              "type": "doc",
              "info": {
                "method": "POST",
                "path": ["API","用户标识管理","用户标识授权"],
                "url": "/openapi/nfc/v1/userId/license",
                "description": '对应用下的用户标识进行授权操作。\<br\/\>' +
                    '<ul>' +
                    '<li>包含授权次数、授权包年、授权永久。授权内容仅在用户表示所在应用中生效;</li>' +
                    '<li>对授权次数将从开发者NFC次数账户扣除，请确保账户剩余次数大于授权次数;</li>' +
                    '<li>对授权包时及授权永久操作将从开发者账户余额中扣除，请确保账户余额充足;</li>' +
                    '<li>授权服务对应的价格，详情请参考用户控制台的”资费标准“;</li>' +
                    '</ul>',
                "params": [
                  {"name": "accessToken","position": "path","required": true,"type": "String","desc": "调用获取AccessToken接口返回的参数"},
                  {"name": "appKey","position": "body","required": true,"type": "String","desc": "应用的appKey"},
                  {"name": "userId","position": "body","required": false,"type": "String","desc": "用户标识"},
                  {"name": "type","position": "body","required": true,"type": "Integer","desc": "授权类型： 1、次数，3、包年，4、永久"},
                  {"name": "amount","position": "body","required": true,"type": "Integer","desc": "授权数量，例如：100次，2年，永久授权数量为1。"},
                ],
                "response": [
                  {"name": "numbers","type": "Integer","desc": "剩余授权次数"},
                  {"name": "endAt","type": "String","desc": "授权到期时间（时间格式为：yyyy-MM-dd HH:mm:ss）"},
                  {"name": "isDurable","type": "Boolean","desc": "是否为永久授权，true 永久授权，false 非永久授权"},
                  {"name": "serialNo","type": "String","desc": "系统流水号，如遇问题可将此号提供给管理员"},
                ],
                "requestDemo": {
                  url:"/openapi/nfc/v1/userId/license?accessToken=2cf97eb510b8e7c1f4ea8d083a91901b",
                  demo: {
                    "amount": 100,
                    "appKey": "2020060213055139pDC40GbHUw60kbaVX",
                    "type": 1,
                    "userId": "1001"
                  }
                },
                "responseDemo":{
                  "code": 0,
                  "message": "成功",
                  "data": {
                    "numbers": 200,
                    "isDurable": false,
                    "endAt": "",
                    "serialNo": "S1_26a82437-39f1-48fd-8873-e5ec233d6172"
                  },
                  "collection": []
                },
                "responseErrorDemo":{
                  "code": 50001,
                  "message": "NFC appKey 错误",
                  "data": null,
                  "collection": []
                }
              }
            },
            {
              "name": "用户标识解绑终端设备",
              "id": "unbind",
              "type": "doc",
              "info": {
                "method": "POST",
                "path": ["API", "用户标识管理", "用户标识解绑终端设备"],
                "url": "/openapi/nfc/v1/userId/unbind",
                "description": '对用户标识和终端设备进行解除绑定操作。\<br\/\>' +
                    '<ul>' +
                    '<li>为防止同一用户标识同一时段在不同终端设备上请求服务，系统对用户表示和终端设备进行绑定操作;</li>' +
                    '<li>绑定操作为系统后台行为，默认为创建用户标识后第一次请求服务，后台系统自动绑定;</li>' +
                    '<li>如用户更换终端设备，新设备读卡前需调用本接口解除绑定，并进行一次读卡操作，读卡成功即自动绑定新终端设备;</li>' +
                    '<li>为防止频繁操作，解绑操作最少间隔4个小时（新终端第一次读卡成功开始计算）;</li>' +
                    '</ul>',
                "params": [
                  {
                    "name": "accessToken",
                    "position": "path",
                    "required": true,
                    "type": "String",
                    "desc": "调用获取AccessToken接口返回的参数"
                  },
                  {"name": "appKey", "position": "body", "required": true, "type": "String", "desc": "应用的appKey"},
                  {"name": "userId", "position": "body", "required": false, "type": "String", "desc": "用户标识"},
                ],
                "response": [
                  {"name": "code", "type": "String", "desc": "状态码，成功为0, 其余为错误码，具体详情请查看错误码说明章节"},
                  {"name": "message", "type": "String", "desc": "状态信息，返回错误详情"}
                ],
                "requestDemo": {
                  url: "/openapi/nfc/v1/userId/unbind?accessToken=2cf97eb510b8e7c1f4ea8d083a91901b",
                  demo: {
                    "appKey": "2020060213055139pDC40GbHUw60kbaVX",
                    "userId": "1001"
                  }
                },
                "responseDemo": {
                  "code": 0,
                  "message": "成功",
                  "data": null,
                  "collection": []
                },
                "responseErrorDemo": {
                  "code": 50005,
                  "message": "用户标识错误",
                  "data": null,
                  "collection": []
                }
              }
            },
          ]
        }
      ]
    }
  ]
}
export  default docJson