<template>
    <div>
        <div class="business-banner">
            <div class="business-description">
                <h1>私有云搭建解决方案</h1>
            </div>
        </div>
        <main>
            <div class="introduction-area">
                <div class="introduction-object left-object"></div>
                <div class="introduction-content right-content">
                    <h2 class="area-title">私有云搭建解决方案</h2>
                    <p class="introduction-content-text">
                        私有云搭建解决方案是基于鱼住·云长久以来的运营经验、技术积累设计而成，能够为客户搭建安全性能高、计算性能强、成本低、可扩展的云系统，满足多样化的非结构化数据存储需求。采用分布式架构部署，使您无需关注服务器、网络等基础设施，轻松、快速的搭建属于自己的云平台。
                        <a href="javascript:void(0)" class="blue-link pull-right" data-toggle="modal" data-target="#contact-modal">点击咨询>></a>
                    </p>
                </div>
            </div>
            <div id="operation" class="gray-bg">
                <div class="introduction-area">
                    <div class="introduction-content left-content">
                        <h2 class="area-title">私有云托管运维</h2>
                        <p class="introduction-content-text">
                            对客户通过我们搭建的私有云系统进行统一管理及运维，从而降低运营成本。平台采用分布式架构部署，不存在单点服务宕机，甚至机房故障导致应用不可用的情况。满足客户在稳定性、响应速度、容量扩展等各方面的需求。
                            <a href="javascript:void(0)" class="blue-link pull-right" data-toggle="modal" data-target="#contact-modal">点击咨询>></a>
                        </p>

                    </div>
                    <div class="introduction-object right-object"></div>
                </div>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="contact-modal" tabindex="-1" role="dialog" aria-labelledby="contactModalLabel">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                            <h3 class="modal-title" id="contactModalLabel">联系方式</h3>
                        </div>
                        <div class="modal-body">
                            <form>
                                <div class="form-group">
                                    <label class="control-label sr-only" for="name">姓名</label>
                                    <input type="text" name="name" class="form-control" id="name" placeholder="姓名">
                                </div>
                                <div class="form-group">
                                    <label class="control-label sr-only" for="email">邮箱</label>
                                    <input type="text" name="email" class="form-control" id="email" placeholder="邮箱">
                                </div>
                                <div class="form-group">
                                    <label class="control-label sr-only" for="phone">联系方式</label>
                                    <input type="text" name="phone" class="form-control" id="phone" placeholder="联系方式">
                                </div>
                                <div class="form-group">
                                    <label class="control-label sr-only" for="remark">备注</label>
                                    <textarea type="text" name="remark" class="form-control" id="remark" rows="7" placeholder="请输入您的具体功能用途"></textarea>
                                </div>
                            </form>
                            <div class="modal-footer">
                                <button type="button" class="blue-btn submit-btn">提 交</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
    export default {
        name: "Business"
    }
</script>

<style scoped>
    @import "../assets/css/business.css";
</style>