<template>
    <div style="overflow-y: hidden">
        <aside class="side-menu js-side-menu" style="overflow-y: scroll" ref="docMenu" id="docMenu">
            <!-- Nav tabs -->
            <ul class="nav nav-tabs" role="tablist">
                <li class="hidden" role="presentation">
                    <a href="#tutorial" aria-controls="tutorial" role="tab" data-toggle="tab">
                        <span>教程</span>
                    </a>
                </li>
                <li role="presentation" class="active">
                    <a href="#api" aria-controls="api" role="tab" data-toggle="tab">
                        <span>API</span>
                    </a>
                </li>
            </ul>
            <!-- Tab panes -->
            <div class="tab-content">
                <div role="tabpanel" class="tab-pane" id="tutorial">
                    <ul class="list-unstyled">
                        <li class="level first-level">
                            <a class="level-title" role="button" data-toggle="collapse" href="#tutorial-level-1"
                               aria-expanded="false" aria-controls="tutorial-level-1">
                                请添加教程
                            </a>
                            <ul class="collapse in list-unstyled document-list" id="tutorial-level-1">
                                <li class="level second-level">
                                    <a href="javascript:void(0)"
                                       class="side-menu-selector js-side-menu-selector">请添加教程</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div role="tabpanel" class="tab-pane active" id="api">
                    <ul class="list-unstyled">
                        <li class="level first-level">
                            <a class="level-title" href="javascript:void(0)">通用说明</a>
                            <ul class="list-unstyled document-list">
                                <li class="level second-level">
                                    <a href="javascript:void(0)" class="side-menu-selector js-side-menu-selector active"
                                       data-tab="Common">通用说明</a>
                                </li>
                            </ul>
                        </li>
                        <li class="level first-level">
                            <a class="level-title" href="javascript:void(0)">人脸识别</a>
                            <ul class="list-unstyled document-list">
                                <li class="level second-level">
                                    <a href="javascript:void(0)" class="side-menu-selector js-side-menu-selector"
                                       data-tab="FaceDetection">人脸检测</a>
                                </li>
                                <li class="level second-level">
                                    <a href="javascript:void(0)" class="side-menu-selector js-side-menu-selector"
                                       data-tab="FaceContrast">人脸对比</a>
                                </li>
<!--                                <li class="level second-level">-->
<!--                                    <a href="javascript:void(0)" class="side-menu-selector js-side-menu-selector"-->
<!--                                       data-tab="FaceSearch">人脸检索</a>-->
<!--                                </li>-->
<!--                                <li class="level second-level">-->
<!--                                    <a class="level-title collapsed side-menu-selector js-switch-icon-btn" role="button"-->
<!--                                       data-toggle="collapse" href="#api-level-face-library" aria-expanded="false"-->
<!--                                       aria-controls="api-level-face-library">-->
<!--                                        人脸库-->
<!--                                        <i class="fa fa-angle-down switch-icon js-switch-icon" aria-hidden="true"></i>-->
<!--                                    </a>-->
<!--                                    <ul class="collapse list-unstyled document-list" id="api-level-face-library">-->
<!--                                        <li class="level third-level">-->
<!--                                            <a href="javascript:void(0)"-->
<!--                                               class="side-menu-selector js-side-menu-selector"-->
<!--                                               data-tab="FaceLibraryShow">浏览人脸库</a>-->
<!--                                        </li>-->
<!--                                        <li class="level third-level">-->
<!--                                            <a href="javascript:void(0)"-->
<!--                                               class="side-menu-selector js-side-menu-selector"-->
<!--                                               data-tab="FaceLibraryNew">创建人脸库</a>-->
<!--                                        </li>-->
<!--                                        <li class="level third-level">-->
<!--                                            <a href="javascript:void(0)"-->
<!--                                               class="side-menu-selector js-side-menu-selector"-->
<!--                                               data-tab="FaceLibraryEdit">修改人脸库</a>-->
<!--                                        </li>-->
<!--                                        <li class="level third-level">-->
<!--                                            <a href="javascript:void(0)"-->
<!--                                               class="side-menu-selector js-side-menu-selector"-->
<!--                                               data-tab="FaceLibraryDelete">删除人脸库</a>-->
<!--                                        </li>-->
<!--                                        <li class="level third-level">-->
<!--                                            <a href="javascript:void(0)"-->
<!--                                               class="side-menu-selector js-side-menu-selector"-->
<!--                                               data-tab="FaceLibraryResourcesShow">人脸库资源列表</a>-->
<!--                                        </li>-->
<!--                                        <li class="level third-level">-->
<!--                                            <a href="javascript:void(0)"-->
<!--                                               class="side-menu-selector js-side-menu-selector"-->
<!--                                               data-tab="FaceLibraryResourcesNew">人脸库资源上传</a>-->
<!--                                        </li>-->
<!--                                        <li class="level third-level">-->
<!--                                            <a href="javascript:void(0)"-->
<!--                                               class="side-menu-selector js-side-menu-selector"-->
<!--                                               data-tab="FaceLibraryResourcesDelete">人脸库资源删除</a>-->
<!--                                        </li>-->
<!--                                    </ul>-->
<!--                                </li>-->
<!--                                <li class="level second-level">-->
<!--                                    <a class="level-title collapsed side-menu-selector js-switch-icon-btn" role="button"-->
<!--                                       data-toggle="collapse" href="#api-level-facs" aria-expanded="false"-->
<!--                                       aria-controls="api-level-facs">-->
<!--                                        人脸-->
<!--                                        <i class="fa fa-angle-down switch-icon js-switch-icon" aria-hidden="true"></i>-->
<!--                                    </a>-->
<!--                                    <ul class="collapse list-unstyled document-list" id="api-level-facs">-->
<!--                                        <li class="level third-level">-->
<!--                                            <a href="javascript:void(0)"-->
<!--                                               class="side-menu-selector js-side-menu-selector"-->
<!--                                               data-tab="FaceAnalysis">人脸分析详情</a>-->
<!--                                        </li>-->
<!--                                        <li class="level third-level">-->
<!--                                            <a href="javascript:void(0)"-->
<!--                                               class="side-menu-selector js-side-menu-selector" data-tab="FaceDetails">人脸详情</a>-->
<!--                                        </li>-->
<!--                                    </ul>-->
<!--                                </li>-->
                            </ul>
                        </li>
<!--                        <li class="level first-level">-->
<!--                        <a class="level-title" href="javascript:void(0)">视频结构化</a>-->
<!--/*                            <ul class="list-unstyled document-list">*/-->
<!--/*                                <li class="level second-level">*/-->
<!--/*                                    <a href="javascript:void(0)" class="side-menu-selector js-side-menu-selector"*/-->
<!--/*                                       data-tab="ClothingRecognition">衣着识别</a>*/-->
<!--/*                                </li>*/-->
<!--                                <li class="level second-level">-->
<!--                                    <a href="javascript:void(0)" class="side-menu-selector js-side-menu-selector"-->
<!--                                       data-tab="LicensePlateRecognition">车牌识别</a>-->
<!--                                </li>-->
<!--                                <li class="level second-level">-->
<!--                                    <a href="javascript:void(0)" class="side-menu-selector js-side-menu-selector"-->
<!--                                       data-tab="VehicleTypeRecognition">车辆类型识别</a>-->
<!--                                </li>-->

<!--                                <li class="level second-level">-->
<!--                                    <a class="level-title collapsed side-menu-selector js-switch-icon-btn" role="button"-->
<!--                                       data-toggle="collapse" href="#api-level-21" aria-expanded="false"-->
<!--                                       aria-controls="api-level-21">-->
<!--                                        对象检测-->
<!--                                        <i class="fa fa-angle-down switch-icon js-switch-icon" aria-hidden="true"></i>-->
<!--                                    </a>-->
<!--                                    <ul class="collapse list-unstyled document-list" id="api-level-21">-->
<!--                                        <li class="level third-level">-->
<!--                                            <a href="javascript:void(0)"-->
<!--                                               class="side-menu-selector js-side-menu-selector"-->
<!--                                               data-tab="ImageDetection">图像检测</a>-->
<!--                                        </li>-->
<!--                                    </ul>-->
<!--                                </li>-->
<!--                            </ul>-->
<!--                        </li>-->
                    </ul>
                </div>
            </div>
        </aside>
        <main class="documents-content js-documents-content" id="docContent"  style="overflow-y: scroll">
            <div v-if="Type === 'Common'"><DocCommon /></div>
            <div v-if="Type === 'ClothingRecognition'"><DocClothing/></div>
            <div v-if="Type === 'FaceAnalysis'"><DocFaceAnalysis/></div>
            <div v-if="Type === 'FaceDetails'"><DocFaceDetails/></div>
            <div v-if="Type === 'FaceContrast'"><DocFaceContrast/></div>
            <div v-if="Type === 'FaceDetection'"><DocFaceDetection/></div>
            <div v-if="Type === 'FaceLibraryDelete'"><DocFaceLibraryDelete/></div>
            <div v-if="Type === 'FaceLibraryEdit'"><DocFaceLibraryEdit/></div>
            <div v-if="Type === 'FaceLibraryNew'"><DocFaceLibraryNew/></div>
            <div v-if="Type === 'FaceLibraryResourcesDelete'"><DocFaceLibraryResourceDelete/></div>
            <div v-if="Type === 'FaceLibraryResourcesNew'"><DocFaceLibraryResourceNew/></div>
            <div v-if="Type === 'FaceLibraryResourcesShow'"><DocFaceLibraryResourceShow/></div>
            <div v-if="Type === 'FaceLibraryShow'"><DocFaceLibraryShow/></div>
            <div v-if="Type === 'FaceSearch'"><DocFaceSearch/></div>
            <div v-if="Type === 'ImageDetection'"><DocImageDetection/></div>
            <div v-if="Type === 'LicensePlateRecognition'"><DocLicensePlateRecognition/></div>
            <div v-if="Type === 'VehicleTypeRecognition'"><DocVehicleTypeRecognition/></div>
            <div v-if="Type === 'VideoDetectionNew'"><DocVideoDetectionNew/></div>
            <div v-if="Type === 'VideoDetectionShow'"><DocVideoDetectionShow/></div>
        </main>
    </div>
</template>

<script>
import DocCommon from "@/views/docs/face/DocCommon";
import DocClothing from "./docs/face/DocClothing";
import DocFaceAnalysis from "./docs/face/DocFaceAnalysis";
import DocFaceDetails from "./docs/face/DocFaceDetails";
import DocFaceContrast from "./docs/face/DocFaceContrast";
import DocFaceDetection from "./docs/face/DocFaceDetection";
import DocFaceLibraryDelete from "./docs/face/DocFaceLibraryDelete";
import DocFaceLibraryEdit from "./docs/face/DocFaceLibraryEdit";
import DocFaceLibraryNew from "./docs/face/DocFaceLibraryNew";
import DocFaceLibraryResourceDelete from "./docs/face/DocFaceLibraryResourceDelete";
import DocFaceLibraryResourceNew from "./docs/face/DocFaceLibraryResourceNew";
import DocFaceLibraryResourceShow from "./docs/face/DocFaceLibraryResourceShow";
import DocFaceLibraryShow from "./docs/face/DocFaceLibraryShow";
import DocFaceSearch from "./docs/face/DocFaceSearch";
import DocImageDetection from "./docs/face/DocImageDetection";
import DocLicensePlateRecognition from "./docs/face/DocLicensePlateRecognition";
import DocVehicleTypeRecognition from "./docs/face/DocVehicleTypeRecognition";
import DocVideoDetectionNew from "./docs/face/DocVideoDetectionNew";
import DocVideoDetectionShow from "./docs/face/DocVideoDetectionShow";

export default {
        name: "documents",
        data: function(){
            return {
                Type: "Common"
            }
        },
        mounted: function(){
            let self  = this;
            $(".side-menu-selector").on("click",function () {
                self.onsideMenuSelectorSelected(this)
            });
            let height  = document.body.clientHeight;
            document.getElementById("docMenu").style.height = height + "px";
            document.getElementById("docMenu").style.overflowY = " scroll";
            document.getElementById("docContent").style.height = height + "px";
            document.getElementById("docContent").style.overflowY = " scroll";

            var hash = window.location.hash.replace("#", "");
            console.log(hash);
            if(hash){
                $('a[data-tab="'+ hash +'"]').click();
            }
        },
        created: function(){
            console.log(document.body.clientHeight);
        },
        components: {
              DocCommon
            , DocClothing
            , DocFaceAnalysis
            , DocFaceDetails
            , DocFaceDetection
            , DocFaceContrast
            , DocFaceLibraryDelete
            , DocFaceLibraryEdit
            , DocFaceLibraryNew
            , DocFaceLibraryResourceDelete
            , DocFaceLibraryResourceNew
            , DocFaceLibraryResourceShow
            , DocFaceLibraryShow
            , DocFaceSearch
            , DocImageDetection
            , DocLicensePlateRecognition
            , DocVehicleTypeRecognition
            , DocVideoDetectionNew
            , DocVideoDetectionShow
        },
        methods :{
            onsideMenuSelectorSelected: function(obj) {
                var self = this;
                $(".side-menu-selector").removeClass('active');
                var tab = $(obj).data('tab');
                if(tab) {
                    $(obj).addClass('active');
                    self.Type = tab;
                    $(".js-documents-content>div").scrollTop(0);
                }
            },
        }
    }
</script>
<style scoped>
    @import "../assets/css/docment.css";
</style>