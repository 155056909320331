<template>
    <div id="face-detection" class="js-article-wrap">
        <ol class="breadcrumb">
            <li><a href="javascript:void(0)">人脸识别</a></li>
            <li class="active">人脸检测</li>
        </ol>
        <!-- <article class="article-group">
          <h3 class="article-title">请求地址</h3>
          <div class="dashed-area request-area">
            <p class="ordinary-text">请求域名： api.aidoing.com.cn</p>
            <p class="ordinary-text">支持协议：http</p>
          </div>
        </article> -->
        <!-- <article class="article-group">
          <h3 class="article-title">关于objectToken</h3>
          <p class="ordinary-text">我们将检测出来的每个人脸信息进行缓存，并返给一个唯一的标识符。通过这个标识符，可以进行对比、分析等操作。缓存时间为48小时，我们建议您将objectToken添加到facearticlebrary(人脸库)。一张图像多次检测获取到的相同人脸的objectToken不一致。</p>
        </article> -->
        <article class="article-group">
            <h3 class="article-title">简介</h3>
            <p class="ordinary-text">检测上传图片中所有的人脸，并对每个人脸生成唯一标识object_token,通过该标识符后续可以进行人脸对比、人脸分析等操作。正式的API_KEY可以对图片进行指定区域检测。</p>
        </article>
        <article class="article-group">
            <h3 class="article-title">图片要求</h3>
            <p class="ordinary-text">图片格式：JPG(JPEG)，PNG</p>
            <p class="ordinary-text">图片像素尺寸：最小 100*100 像素，最大 4096*4096 像素</p>
            <p class="ordinary-text">图片文件大小：2 MB</p>
            <p class="ordinary-text">最小人脸像素尺寸： 系统能够检测到的人脸框为一个正方形，正方形边长的最小值不低于100 像素</p>
        </article>
        <article class="article-group">
            <h3 class="article-title">调用地址</h3>
            <pre class="dashed-area">/face/v1/detect</pre>
        </article>
        <article class="article-group">
            <h3 class="article-title">调用方式</h3>
            <pre class="dashed-area">POST</pre>
        </article>
        <article class="article-group">
          <h3 class="article-title">请求数据格式</h3>
          <pre class="dashed-area">Content-Type: multipart/form-data</pre>
        </article>
        <article class="article-group">
            <h3 class="article-title">请求参数</h3>
            <table class="table table-area">
                <thead>
                <tr>
                    <th>是否必需</th>
                    <th>参数名</th>
                    <th>类型</th>
                    <th>说明</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>必须</td>
                    <td>appKey</td>
                    <td>String</td>
                    <td>API key</td>
                </tr>
                <tr>
                    <td>必须</td>
                    <td>appSecret</td>
                    <td>String</td>
                    <td>API secret</td>
                </tr>
                <tr>
                    <td rowspan="3">必须（三选一）</td>
                    <td>imageUrl</td>
                    <td>String</td>
                    <td>图片的URL</td>
                </tr>
                <tr>
                    <!-- <td>必须</td> -->
                    <td>imageData</td>
                    <td>File</td>
                    <td>上传的图片二进制文件。需post multipart/form-data方式</td>
                </tr>
                <tr>
                    <!-- <td>必须</td> -->
                    <td>imageBase64</td>
                    <td>String</td>
                    <td>Base64编码的二进制图片数据</td>
                </tr>
                <tr>
                    <td>可选</td>
                    <td>callbackAddress</td>
                    <td>String</td>
                    <td>结果以异步回调的方式返回。回调地址需要urlencode参数为空或者不传为同步返回</td>
                </tr>
                <tr>
                    <td>可选</td>
                    <td>returnType</td>
                    <td>int</td>
                    <td>
                        <p>返回类型：</p>
                        <p>0：返回人脸矩形框位置</p>
                        <p>1：返回人脸关键点</p>
                        <p>2：返回检测到的人脸属性</p>
                        <p>3：返回所有信息</p>
                        <p>参数默认为0</p>
                    </td>
                </tr>
                <tr>
                    <td>可选（仅供正式API使用）</td>
                    <td>objectRectangle</td>
                    <td>String</td>
                    <td>按指定的位置检测人脸信息。分别为左上角横坐标，左上角纵坐标，宽度，高度。中间用“,”分割。如：“400,300,200,100”</td>
                </tr>
                </tbody>
            </table>
        </article>
        <article class="article-group">
            <h3 class="article-title">返回参数</h3>
            <p>数据格式JSON：</p>
            <table class="table table-area">
                <thead>
                <tr>
                    <th>字段</th>
                    <th>类型</th>
                    <th>说明</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>state</td>
                    <td>String</td>
                    <td>
                        <p>返回结果类型，值分为：</p>
                        <p>SUCCESS:成功</p>
                        <p>FAILURE:失败</p>
                    </td>
                </tr>
                <tr>
                    <td>errorMessage</td>
                    <td>String</td>
                    <td>错误信息，成功返回值为空字符串</td>
                </tr>
                <tr>
                    <td>faces</td>
                    <td>Array</td>
                    <td>检测到的人脸详细信息，具体内容见下文</td>
                </tr>
                <tr>
                    <td>requestId</td>
                    <td>String</td>
                    <td>每次请求唯一的字符串</td>
                </tr>
                </tbody>
            </table>

            <p>faces数据结构：</p>
            <table class="table table-area">
                <thead>
                <tr>
                    <th>字段</th>
                    <th>类型</th>
                    <th>说明</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>faceLocate</td>
                    <td>Object</td>
                    <td>
                        <p>人脸框定位坐标，包括下面属性，每个属性都为int类型：</p>
                        <ul>
                            <li>left ：人脸框左上角横坐标</li>
                            <li>toli：人脸框左上角纵坐标</li>
                            <li>width：人脸框宽度</li>
                            <li>height：人脸框高度</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>attributes</td>
                    <td>Object</td>
                    <td>
                        <p>检测到人脸属性：如</p>
                        <ul>
                            <li>gender(String)：male(男性)，female(女性)</li>
                            <li>age(int)：年龄</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>landmark</td>
                    <td>Array</td>
                    <td>人脸关键点坐标数组</td>
                </tr>
                <tr>
                    <td>objectToken</td>
                    <td>String</td>
                    <td>返回的人脸唯一标识符，临时缓存48小时，存入人脸库中可以长久保存</td>
                </tr>
                </tbody>
            </table>

            <p>请求成功返回示例：</p>
            <pre class="dashed-area">
{
    "state": "SUCCESS",
    "errorMessage": "",
    "requestId": "ed376c8e9-46d7-aee6-ef8cd0335-1558c7",
    "faces": [
        {
            "faceLocate": {
                "left": 133,
                "top": 215,
                "width": 260,
                "height": 260
            },
            "attributes": {
                "gender": "Male",
                "age": 18
            },
            "landmark": [
                {
                    "x": 123,
                    "y": 23
                },
                {
                    "x": 123,
                    "y": 23
                }
            ],
            "objectToken": "c0ZDBmZjMwNDBhODMzMzQ4ZDM="
        },
        {
            "faceLocate": {
                "left": 133,
                "top": 215,
                "width": 260,
                "height": 260
            },
            "attributes": {
                "gender": "Male",
                "age": 18
            },
            "landmark": [
                {
                    "x": 123,
                    "y": 23
                },
                {
                    "x": 123,
                    "y": 23
                }
            ],
            "objectToken": "c0ZDBmZjMwNDBhODMzMzQ4ZDM="
        }
    ]
}</pre>

            <p>请求失败返回示例：</p>
            <pre class="dashed-area">
{
    "state": "FAILURE",
    "errorMessage": "ERROR_IMAGE_IS_MUST",
    "requestId": "ed376c8e9-46d7-aee6-ef8cd0335-1558c7",
    "faces": null
}</pre>
        </article>
        <article class="article-group">
            <h3 class="article-title">错误信息</h3>
            <table class="table table-area error-table">
                <thead>
                <tr>
                    <th>错误信息</th>
                    <th>说明</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>ERROR_IMAGE_IS_MUST</td>
                    <td>图片信息是必须的</td>
                </tr>
                <tr>
                    <td>ERROR_KEY_ISNOT_LEGAL</td>
                    <td>Key不合法</td>
                </tr>
                <tr>
                    <td>ERROR_SECRET_ISNOT_LEGAL</td>
                    <td>Secret不合法</td>
                </tr>
                <tr>
                    <td>ERROR_SOURCE_FAILED</td>
                    <td>获取资源失败</td>
                </tr>
                <tr>
                    <td>ERROR_FACE_DETECTED_FAILED</td>
                    <td>人脸检测失败</td>
                </tr>
                <tr>
                    <td>ERROR_FACE_ZERO_DETECTED</td>
                    <td>未检测到人脸信息</td>
                </tr>
                <tr>
                    <td>ERROR_FACE_NO_PROPERTY_DETECTED</td>
                    <td>为检测到人脸相关属性</td>
                </tr>
                <tr>
                    <td>ERROR_FACE_FEATURE_FAILED</td>
                    <td>未从图像中提取到人脸特征</td>
                </tr>
                </tbody>
            </table>
        </article>
    </div>
</template>

<script>
    export default {
        name: "DocFaceDetection"
    }
</script>

<style scoped>

</style>