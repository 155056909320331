<template>
  <div>
      <TechnicalHeader
              icon="face_search_icon"
              name="人脸检索技术"
              title="提供一张人脸，在人脸库中搜索相似的。"
              domId="FaceSearch"
      />
      <main>
          <div class="technology-area small-technology-area">
              <div class="technology-content">
                  <div class="describe-image-area">
                      <img src="../../assets/images/face_search_describe.png">
                  </div>
                  <div class="technology-describe-area">
                      <p class="technology-describe-title">技术描述</p>
                      <p class="technology-describe-text">提供一张人脸，我们在人脸库中进行搜索，分析并判断是否是库中的某个人，或者返回相应的排序结果。经过我们的安防及业务大数据平台的实践，人脸搜索技术能够实现超大规模的快速准确检索。</p>
                      <div class="technology-line"></div>
                  </div>
              </div>
          </div>

          <div class="technology-area">
              <div class="technology-content">
                  <p class="technology-scene-title">应用场景</p>
                  <ul class="technology-scene-list">
                      <li data-code="0" class="active">
                          <span>定位可疑人员</span>
                          <div class="scene-line"></div>
                      </li>
                      <li data-code="1">
                          <span>访客登记</span>
                          <div class="scene-line"></div>
                      </li>
                  </ul>

                  <div class="scene-image-area">
                      <img src="../../assets/images/face_search_scene1.png">
                      <img src="../../assets/images/face_search_scene2.png">
                  </div>
              </div>
          </div>

          <div class="technology-area technology-buy-area">
              <div class="technology-content">
                  <p class="technology-buy-title">即刻拥有鱼住人脸检索技术</p>
                  <p class="technology-buy-text">享受更高的性价比和技术优势</p>
                  <a href="price.html" class="buy-btn">立即购买</a>
              </div>
          </div>
      </main>
  </div>
</template>

<script>
    import TechnicalHeader from '@/components/TechnicalHeader.vue';
    export default {
        name: "FaceRetrieve",
        data: function () {
            return {
                code: 0
            };
        },
        mounted: function () {
            let self = this;
            this.$nextTick(() => {
                this.common.scrollDemo();
            });
            $(".technology-scene-list li").on("click", function () {
                let code = $(this).data("code");
                if (code != self.code) {
                    $(".technology-scene-list li.active").removeClass("active");
                    $(this).addClass("active");
                    $(".scene-image-area").find("img").fadeOut(500).eq(code).fadeIn(500);
                    self.code = code
                }
            })
        },
        components: {
            TechnicalHeader
        }
    }
</script>
<style scoped>
    .technology-title-area{
        background: url(../../assets/images/face_search_bg.png) center no-repeat;
        background-size: cover;
    }
    .technology-scene-list{
        width: 400px;
    }
    @import "../../assets/css/face_common.css";
</style>