<template>
    <div>
        <div class="price-banner">
            <div class="price-description">
                <h1>价格说明</h1>
                <p>我们平台遵循三种价格方案：免费，按次计费、包时计费三种，提供更有弹性、更加灵活的价格方案。</p>
            </div>
        </div>
        <main class="content-area">
            <div class="price-type free-trial">
                <h2><span class="icon free-trial-icon"></span>免费试用</h2>
                <p class="price-type-intro">
                    试用规则说明：为了给用户可以更好的开发体验，每一个接口用户都可以免费使用。最高可达2QPS。
                    <a href="javascript:void(0)" class="blue-link free-trial-btn">点击免费试用>></a>
                </p>
            </div>
            <div class="price-type">
                <h2><span class="icon web-api-icon"></span>Web API收费项</h2>
                <div class="price-small-type">
                    <h3>次数收费</h3>
                    <p class="price-type-intro">
                        预充值账户，按实际调用次数来进行。
                        <a href="prices_show.html?type=1" class="blue-link">充值>></a>
                    </p>
                    <table id="recharge-table" class="table">
                        <thead>
                        <tr>
                            <th>种类</th>
                            <th>API</th>
                            <th>收费内容</th>
                            <th>调用价格（元/次）</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="data in rechargeData">
                            <td>{{data.parentName}}</td>
                            <td>{{data.goodsName}}</td>
                            <td>{{data.remark}}</td>
                            <td>{{data.price}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="price-small-type">
                    <h3>包时收费</h3>
                    <p class="price-type-intro">
                        预付购买“包时计费”资源包，在资源包有效时间内调用次数不限制。有效时间结束，默认会转到按次计费服务。
                        <a href="prices_show.html?type=2" class="blue-link">点击购买>></a>
                    </p>
                    <table id="purchase-table" class="table">
                        <thead>
                        <tr>
                            <th>种类</th>
                            <th>收费内容</th>
                            <th>按小时计费</th>
                            <th>按月计费</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="data in allPurchaseData">
                            <td>{{data.goodsName}}</td>
                            <td>{{data.remark}}</td>
                            <td>{{data.chargeUnitHour}}</td>
                            <td>{{data.chargeUnitMonth}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="price-type">
                <h2><span class="icon sdk-icon"></span>SDK收费项</h2>
                <p class="price-type-intro">
                    需要提前购买资源包，可根据需求按月、按年来买。
                    <a href="prices_show.html?type=3" class="blue-link">点击购买>></a>
                </p>
                <table id="sdk-table" class="table">
                    <thead>
                    <tr>
                        <th>种类</th>
                        <th>收费内容</th>
                        <th>可授权设备数量</th>
                        <th>包月价格</th>
                        <th>包年价格</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="data in allSdkPriceData">
                        <td>{{data.goodsName}}</td>
                        <td>{{data.remark}}</td>
                        <td>{{data.authEquipmentNumber}}</td>
                        <td>{{data.chargeUnitMonth}}</td>
                        <td>{{data.chargeUnitHour}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </main>
        <div class="bottom-tips">
            <p class="tips-title">您是否对接入流程或商务合作有疑问？</p>
            <p>
                <small>如果您对接入流程或商务合作有疑问，请拨打售前电话：028 82880293</small>
            </p>
        </div>

    </div>
</template>

<script>
    import {getRechargeData, getAllPurchase, getAllSdkPrice} from "../assets/js/api";
    import {showNormalModal} from "../assets/js/common";
    import axios from 'axios';
    export default {
        name: "Price",
        data: function () {
            return {
                rechargeData:[],
                allPurchaseData:[],
                allSdkPriceData:[],
            };
        },
        created: function () {
            this.getAllPurchase(0);
            this.getAllPurchase(1);
            this.getAllPurchase(2);
        },
        methods: {
            getAllPurchase: function (type) {
                let self = this;
                let url =  type===0?getRechargeData:(type===1?getAllPurchase:getAllSdkPrice);
                axios.get(url).then((res) => {
                    let data = res.data;
                    if (data && data.success) {
                        switch (type) {
                            case 0: self.rechargeData = data.collection; break;
                            case 1: self.allPurchaseData = data.collection; break;
                            case 2: self.allSdkPriceData = data.collection; break;
                        }
                    } else {
                        showNormalModal("服务器内部错误");
                    }
                }).catch((res) => {
                    console.log(res.response);
                    showNormalModal("服务器内部错误");
                })
            }
        },
    }
</script>

<style scoped>
    @import "../assets/css/price.css";
</style>