<template>
    <div id="face-library-resources-delete" class="js-article-wrap">
        <ol class="breadcrumb">
            <li><a href="javascript:void(0)">人脸识别</a></li>
            <li><a href="javascript:void(0)">人脸库</a></li>
            <li class="active">人脸库资源删除</li>
        </ol>
        <article class="article-group">
            <h3 class="article-title">简介</h3>
            <p class="ordinary-text">删除一个faceLibrary（人脸库）。mq通知后台服务器进行删除操作。</p>
        </article>
        <article class="article-group">
            <h3 class="article-title">调用地址</h3>
            <pre class="dashed-area">/face/v1/library/removeFace</pre>
        </article>
        <article class="article-group">
            <h3 class="article-title">调用方式</h3>
            <pre class="dashed-area">POST</pre>
        </article>
        <article class="article-group">
            <h3 class="article-title">请求参数</h3>
            <table class="table table-area">
                <thead>
                <tr>
                    <th>是否必需</th>
                    <th>参数名</th>
                    <th>类型</th>
                    <th>说明</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>必须</td>
                    <td>appKey</td>
                    <td>String</td>
                    <td>API key</td>
                </tr>
                <tr>
                    <td>必须</td>
                    <td>appSecret</td>
                    <td>String</td>
                    <td>API secret</td>
                </tr>
                <tr>
                    <td>必须</td>
                    <td>libraryId</td>
                    <td>String</td>
                    <td>人脸库ID</td>
                </tr>
                <tr>
                    <td>必须</td>
                    <td>objectTokens</td>
                    <td>String</td>
                    <td>人脸标识，可以上传一个或多个objectToken，用逗号隔开。如果值为“RemoveAllTokens”则清空人脸库。</td>
                </tr>
                </tbody>
            </table>
        </article>
        <article class="article-group">
            <h3 class="article-title">返回参数</h3>
            <p>数据格式JSON：</p>
            <table class="table table-area">
                <thead>
                <tr>
                    <th>字段</th>
                    <th>类型</th>
                    <th>说明</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>state</td>
                    <td>String</td>
                    <td>
                        <p>返回结果类型，值分为：</p>
                        <p>SUCCESS:成功</p>
                        <p>FAILURE:失败</p>
                    </td>
                </tr>
                <tr>
                    <td>requestId</td>
                    <td>String</td>
                    <td>每次请求唯一的字符串</td>
                </tr>
                <tr>
                    <td>libraryId</td>
                    <td>String</td>
                    <td>人脸库ID</td>
                </tr>
                <tr>
                    <td>objectSuccessCount</td>
                    <td>Int</td>
                    <td>本次删除成功的数量</td>
                </tr>
                <tr>
                    <td>objectCount</td>
                    <td>Int</td>
                    <td>删除完成后库文件数量</td>
                </tr>
                <tr>
                    <td>errorMessage</td>
                    <td>String</td>
                    <td>错误信息，成功返回值为空字符串</td>
                </tr>
                <tr>
                    <td>failureReason</td>
                    <td>Array</td>
                    <td>未操作成功的objectToken数组</td>
                </tr>
                </tbody>
            </table>

            <p>failureReason数据结构：</p>
            <table class="table table-area">
                <thead>
                <tr>
                    <th>字段</th>
                    <th>类型</th>
                    <th>说明</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>objectToken</td>
                    <td>String</td>
                    <td>
                        <p>未添加成功的objectToken</p>
                    </td>
                </tr>
                <tr>
                    <td>errorMsg</td>
                    <td>String</td>
                    <td>
                        <p>失败原因</p>
                    </td>
                </tr>
                </tbody>
            </table>

            <p>请求成功返回示例：</p>
            <pre class="dashed-area">
{
    "state": "SUCCESS",
    "errorMessage": "",
    "requestId": "ed376c8e9-46d7-aee6-ef8cd0335-1558c7"，
    "libraryId": "29809738937793F8DF7936C69B84ED23",
    "objectSuccessCount":5， //成功数量
    "objectCount":25，		//人脸库删除后总量
    "failureReason ": [{     //失败的原因
        "objectToken": "Mzc3OTNGOERGNzkzNkM2OUI4NEVEMjM=",
        "errorMsg": " ERROR_OBJECTTOKN_DOESNOT_EXIST"   // token不存在
    },
    {
        "objectToken": "Mzc3OTNGOERGNzkzNkM2OUI4NEVEMjM=",
        "errorMsg": "ERROR_OBJECTTOKN_DOESNOT_EXIST"  //token不存在
    }]
}
</pre>

            <p>请求失败返回示例：</p>
            <pre class="dashed-area">
{
    "state": "FAILURE",
    "errorMessage": "ERROR_LIBRARYID_IS_MUST"
}</pre>
        </article>
        <article class="article-group">
            <h3 class="article-title">错误信息</h3>
            <table class="table table-area error-table">
                <thead>
                <tr>
                    <th>错误信息</th>
                    <th>说明</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>ERROR_LIBRARYID_IS_MUST</td>
                    <td>人脸库ID是必须的</td>
                </tr>
                <tr>
                    <td>ERROR_KEY_ISNOT_LEGAL</td>
                    <td>Key不合法</td>
                </tr>
                <tr>
                    <td>ERROR_SECRET_ISNOT_LEGAL</td>
                    <td>Secret不合法</td>
                </tr>
                </tbody>
            </table>
        </article>
    </div>
</template>

<script>
    export default {
        name: "DocFaceLibraryResourceDelete"
    }
</script>

<style scoped>

</style>