<template>
    <div id="face-search" class="js-article-wrap">
        <ol class="breadcrumb">
            <li><a href="javascript:void(0)">人脸识别</a></li>
            <li class="active">人脸检索</li>
        </ol>
        <article class="article-group">
            <h3 class="article-title">简介</h3>
            <p class="ordinary-text">在已经存在的人脸库中，找出和给定的人脸相似度最高的一张或者多张人脸，返回置信度和阈值。</p>
            <p class="ordinary-text">支持传入objectToken进行查找。</p>
            <p class="ordinary-text">上传的图像中如果检测到多张人脸，则仅对区域面积最大的人脸进行检索</p>
        </article>
        <article class="article-group">
            <h3 class="article-title">图片要求</h3>
            <p class="ordinary-text">图片格式：JPG(JPEG)，PNG</p>
            <p class="ordinary-text">图片像素尺寸：最小 100*100 像素，最大 4096*4096 像素</p>
            <p class="ordinary-text">图片文件大小：2 MB</p>
            <p class="ordinary-text">最小人脸像素尺寸： 系统能够检测到的人脸框为一个正方形，正方形边长的最小值不低于100 像素</p>
        </article>
        <article class="article-group">
            <h3 class="article-title">调用地址</h3>
            <pre class="dashed-area">/face/v1/search</pre>
        </article>
        <article class="article-group">
            <h3 class="article-title">调用方式</h3>
            <pre class="dashed-area">POST</pre>
        </article>
        <article class="article-group">
            <h3 class="article-title">请求参数</h3>
            <table class="table table-area">
                <thead>
                <tr>
                    <th>是否必需</th>
                    <th>参数名</th>
                    <th>类型</th>
                    <th>说明</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>必须</td>
                    <td>appKey</td>
                    <td>String</td>
                    <td>API key</td>
                </tr>
                <tr>
                    <td>必须</td>
                    <td>appSecret</td>
                    <td>String</td>
                    <td>API secret</td>
                </tr>
                <tr>
                    <td rowspan="4">必须（四选一）</td>
                    <td>objectTokenOne</td>
                    <td>String</td>
                    <td>第一张人脸标识</td>
                </tr>
                <tr>
                    <td>imageUrlOne</td>
                    <td>String</td>
                    <td>图片的URL</td>
                </tr>
                <tr>
                    <td>imageDataOne</td>
                    <td>File</td>
                    <td>上传的图片二进制文件。需post multipart/form-data方式</td>
                </tr>
                <tr>
                    <td>imageBase64One</td>
                    <td>String</td>
                    <td>Base64编码的二进制图片数据</td>
                </tr>
                <tr>
                    <td>必须</td>
                    <td>uniquenessId</td>
                    <td>String</td>
                    <td>
                        <p>资源ID：</p>
                    </td>
                </tr>
                <tr>
                    <td>可选</td>
                    <td>resultTop</td>
                    <td>int</td>
                    <td>返回的结果集条目数， 数值为1-5 ，默认为1</td>
                </tr>
                </tbody>
            </table>
        </article>
        <article class="article-group">
            <h3 class="article-title">返回参数</h3>
            <p>数据格式JSON：</p>
            <table class="table table-area">
                <thead>
                <tr>
                    <th>字段</th>
                    <th>类型</th>
                    <th>说明</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>state</td>
                    <td>String</td>
                    <td>
                        <p>返回结果类型，值分为：</p>
                        <p>SUCCESS:成功</p>
                        <p>FAILURE:失败</p>
                    </td>
                </tr>
                <tr>
                    <td>requestId</td>
                    <td>String</td>
                    <td>每次请求唯一的字符串</td>
                </tr>
                <tr>
                    <td>errorMessage</td>
                    <td>String</td>
                    <td>错误信息，成功返回值为空字符串</td>
                </tr>
                <tr>
                    <td>thresholds</td>
                    <td>Float</td>
                    <td>用于参考的置信度阈值，精确到小数点后两位</td>
                </tr>
                <tr>
                    <td>face</td>
                    <td>Object</td>
                    <td>供检测的人脸照片，结构见下文</td>
                </tr>
                <tr>
                    <td>results</td>
                    <td>Array</td>
                    <td>人脸搜索结果的对象数组。未搜索到则返回null；具体结构见下文</td>
                </tr>
                </tbody>
            </table>

            <p>face结构：</p>
            <table class="table table-area">
                <thead>
                <tr>
                    <th>字段</th>
                    <th>类型</th>
                    <th>说明</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>faceLocate</td>
                    <td>Object</td>
                    <td>
                        <p>人脸框定位坐标，包括下面属性，每个属性都为int类型：</p>
                        <ul>
                            <li>left ：人脸框左上角横坐标</li>
                            <li>toli：人脸框左上角纵坐标</li>
                            <li>width：人脸框宽度</li>
                            <li>height：人脸框高度</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>imageUrl</td>
                    <td>String</td>
                    <td>
                        <p>图片访问路径</p>
                    </td>
                </tr>
                </tbody>
            </table>

            <p>result数据结构：</p>
            <table class="table table-area">
                <thead>
                <tr>
                    <th>字段</th>
                    <th>类型</th>
                    <th>说明</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>faceLocate</td>
                    <td>Object</td>
                    <td>
                        <p>人脸框定位坐标，包括下面属性，每个属性都为int类型：</p>
                        <ul>
                            <li>left ：人脸框左上角横坐标</li>
                            <li>toli：人脸框左上角纵坐标</li>
                            <li>width：人脸框宽度</li>
                            <li>height：人脸框高度</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>objectToken</td>
                    <td>String</td>
                    <td>
                        <p>返回的人脸唯一标识符，临时缓存48小时，存入人脸库中可以长久保存。</p>
                    </td>
                </tr>
                <tr>
                    <td>imageUrl</td>
                    <td>String</td>
                    <td>
                        <p>图片访问路径</p>
                    </td>
                </tr>
                <tr>
                    <td>confidence</td>
                    <td>Float</td>
                    <td>对比结果置信度，结果最小为0，最大为100，精确到小数点后三位。数值越大说明是同一个人的可能性越大</td>
                </tr>
                </tbody>
            </table>

            <p>请求成功返回示例：</p>
            <pre class="dashed-area">
{
    "state": "SUCCESS",
    "errorMessage": "",
    "thresholds": 16.5,
    "requestId": "ed376c8e9-46d7-aee6-ef8cd0335-1558c7",
    "face": {
        "faceLocate": {
            "left": 133,
            "top": 215,
            "width": 260,
            "height": 260
        },
        "imageUrl": "http://host/imageurl ",//原图片地址
    },
    "results": [{
        "faceLocate": {
            "left": 133,
            "top": 215,
            "width": 260,
            "height": 260
        },
        "confidence": 78.563,
        "imageUrl": "http://host/imageurl",
    },
    {
        "faceLocate": {
            "left": 133,
            "top": 215,
            "width": 260,
            "height": 260
        },
        "confidence": 80.185,
        "imageUrl": "http://host/imageurl",
    }]
}
</pre>

            <p>请求失败返回示例：</p>
            <pre class="dashed-area">
{
    "state": "FAILURE",
    "errorMessage": "ERROR_IMAGE_IS_MUST"
}</pre>
        </article>
        <article class="article-group">
            <h3 class="article-title">错误信息</h3>
            <table class="table table-area error-table">
                <thead>
                <tr>
                    <th>错误信息</th>
                    <th>说明</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>ERROR_IMAGE_IS_MUST</td>
                    <td>图片信息是必须的</td>
                </tr>
                <tr>
                    <td>ERROR_KEY_ISNOT_LEGAL</td>
                    <td>Key不合法</td>
                </tr>
                <tr>
                    <td>ERROR_SECRET_ISNOT_LEGAL</td>
                    <td>Secret不合法</td>
                </tr>
                <tr>
                    <td>ERROR_SOURCE_FAILED</td>
                    <td>获取资源失败</td>
                </tr>
                <tr>
                    <td>RROR_FACE_LIBRARYID_INVALID</td>
                    <td>无效的人脸库ID</td>
                </tr>
                </tbody>
            </table>
        </article>
    </div>
</template>

<script>
    export default {
        name: "DocFaceSearch"
    }
</script>

<style scoped>

</style>