import { render, staticRenderFns } from "./DiscernmentIdCard.vue?vue&type=template&id=7c81ba14&scoped=true&"
import script from "./DiscernmentIdCard.vue?vue&type=script&lang=js&"
export * from "./DiscernmentIdCard.vue?vue&type=script&lang=js&"
import style0 from "./DiscernmentIdCard.vue?vue&type=style&index=0&id=7c81ba14&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c81ba14",
  null
  
)

export default component.exports