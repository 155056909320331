<template>
  <div id="common-content" class="js-article-wrap">
    <ol class="breadcrumb">
      <li><a href="javascript:void(0)">接入指南</a></li>
    </ol>
    <h3 class="article-title">1.注册开发者账号</h3>
    <article class="article-group" style="margin-top: 15px">
      <h4 class="article-title">1.1注册并登录鱼住云控制台</h4>
      <div class="dashed-area request-area">
        <p>进入<a href="https://login.aidoing.com.cn"> <strong> 鱼住云控制台登录中心 （https://login.aidoing.com.cn）</strong></a>，若您有账号，则直接登录；没有则点击<strong>注册</strong>进入注册页面，进行注册，成功后进行登陆。进入
          <strong>鱼住人工智能开放平台</strong>，点击右上角 “控制台”
          进入鱼住云开发者平台。</p>
        <p style="margin-top: 15px">进入鱼住云登录中心进行注册</p>
        <img style="width: 60%" :src="logonUrl" alt=""/>

        <p style="margin-top: 15px">进入鱼住云首页</p>
        <img style="width: 60%" :src="homeUrl" alt=""/>
        
        <p style="margin-top: 15px">进入鱼住云控制台</p>
        <img style="width: 60%" :src="consoleUrl" alt=""/>
        <p style="margin-top: 15px"><strong>现在，您已经注册成功！！！</strong></p>
      </div>
    </article>
    <article class="article-group">
      <h4 class="article-title">1.2 成为开发者</h4>
      <div class="dashed-area request-area">
        <p>进入控制台后，在 “个人中心/个人资料/开发设置” 进行设置，我们会通过您注册时候的手机号发送短信进行验证，通过验证后即成为开发者，平台会为您生成
          <strong>开发者Key</strong> 和 <strong>开发者密钥</strong>。<strong>开发者密钥在第一次明文显示后隐藏，请务必牢记！</strong>若忘记，在通过注册手机短信验证后可查看或重置。
        </p>
        <p style="margin-top: 15px">进入开发者中心</p>
        <img style="width: 60%" :src="developerUrl" alt=""/>

        <p style="margin-top: 15px">发送短信验证码成为开发者</p>
        <img style="width: 60%" :src="developerCodeUrl" alt=""/>

        <p style="margin-top: 15px">现在您已经成为开发者，请妥善保存开发者key和开发者密钥（<strong>密钥初次生成会展示在页面，刷新后需要发送短信才可查看或者重置</strong>）</p>
        <img style="width: 60%" :src="developerReadyUrl" alt=""/>
        <p style="margin-top: 15px"><strong><span style="color: darkred">IP白名单开发环境可设置为“0.0.0.0”，建议生产环境设置为您的服务器IP地址</span></strong></p>
      </div>
    </article>
    <h3 class="article-title">2.创建 NFC 应用</h3>
    <article class="article-group" style="margin-top: 15px">
      <h3 class="article-title">2.1创建应用</h3>
      <div class="dashed-area request-area">
        <p>一、应用说明</p>
        <p>NFC应用是开发者调用身份识别服务的唯一凭证。应用创建完成后，请开发者谨慎保管应用Key和应用Secret。</p>
        <p>应用Key和应用Secret初次创建成功后会展示在页面，重新进入页面会隐藏，可点击右侧的按钮进行展示或者刷新。</p>
        <p>应用计费模式分为：共享计费、独立计费</p>
        <p>1：应用负责人信息，请认真填写。使用过程中遇到问题，将通过短信或者邮箱的方式通知用户。</p>
        <p>2：应用名称不可重复。</p>
        <p>3：每个账户可免费添加10个应用（含已删除）。如有特殊要求,请联系我们。</p>
        <p>4：每个新建【共享计费】类型的应用免费赠送【20】次,以供用户体验。</p>
        <p>5：应用计费模式分为：共享计费和独立计费</p>
        <p>6：共享计费：该应用的使用者共享应用次数。</p>
        <ul>
          <li>优势：操作简单，管理方便。</li>
          <li>劣势：无法准确管理每个使用者，应用次数用完，则所有使用者停止授权服务。</li>
        </ul>
        <p>7：独立计费：应用下每个使用者的都有自己的授权服务。</p>
        <ul>
          <li>优势：可以对应用每个使用者单独授权服务（授权次数、授权时间、授权永久），单个使用者停止授权不影响其他使用者的授权服务。</li>
          <li>劣势：操作复杂，需要后台对接开放API，对每个应用使用者创建“用户标识”。</li>
        </ul>

        <p style="margin-top: 15px">进入控制台NFC应用管理</p>
        <img style="width: 60%" :src="nfcAppCreateEmptyUrl" alt=""/>

        <p style="margin-top: 15px">开始创建NFC应用</p>
        <img style="width: 60%" :src="nfcAppCreateUrl" alt=""/>

        <p style="margin-top: 15px">应用创建完成</p>
        <img style="width: 60%" :src="nfcAppCreatedUrl" alt=""/>

      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: "DocMustRead",
  data() {
    return {
      homeUrl: require("../../../assets/images/doc/doc_home.png"),
      consoleUrl: require("../../../assets/images/doc/doc_console.png"),
      logonUrl: require("../../../assets/images/doc/doc_logon.png"),
      developerUrl: require("../../../assets/images/doc/doc_developer.png"),
      developerCodeUrl: require("../../../assets/images/doc/doc_developer_code.png"),
      developerReadyUrl: require("../../../assets/images/doc/doc_developer_ready.png"),
      nfcAppCreateUrl: require("../../../assets/images/doc/doc_nfc_app_create.png"),
      nfcAppCreatedUrl: require("../../../assets/images/doc/doc_nfc_app_created.png"),
      nfcAppCreateEmptyUrl: require("../../../assets/images/doc/doc_nfc_app_create_empty.png")
    }
  }
}
</script>

<style scoped>

</style>