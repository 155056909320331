<template>
    <div id="face-library-resources-show" class="js-article-wrap">
        <ol class="breadcrumb">
            <li><a href="javascript:void(0)">人脸识别</a></li>
            <li><a href="javascript:void(0)">人脸库</a></li>
            <li class="active">人脸库资源列表</li>
        </ol>
        <article class="article-group">
            <h3 class="article-title">简介</h3>
            <p class="ordinary-text">查询一个faceLibrary（人脸库）中所有的objectToken。</p>
        </article>
        <article class="article-group">
            <h3 class="article-title">调用地址</h3>
            <pre class="dashed-area">/face/v1/library/getLibraryDetails</pre>
        </article>
        <article class="article-group">
            <h3 class="article-title">调用方式</h3>
            <pre class="dashed-area">POST</pre>
        </article>
        <article class="article-group">
            <h3 class="article-title">请求参数</h3>
            <table class="table table-area">
                <thead>
                <tr>
                    <th>是否必需</th>
                    <th>参数名</th>
                    <th>类型</th>
                    <th>说明</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>必须</td>
                    <td>appKey</td>
                    <td>String</td>
                    <td>API key</td>
                </tr>
                <tr>
                    <td>必须</td>
                    <td>appSecret</td>
                    <td>String</td>
                    <td>API secret</td>
                </tr>
                <tr>
                    <td>必须</td>
                    <td>libraryId</td>
                    <td>String</td>
                    <td>人脸库ID</td>
                </tr>
                </tbody>
            </table>
        </article>
        <article class="article-group">
            <h3 class="article-title">返回参数</h3>
            <p>数据格式JSON：</p>
            <table class="table table-area">
                <thead>
                <tr>
                    <th>字段</th>
                    <th>类型</th>
                    <th>说明</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>state</td>
                    <td>String</td>
                    <td>
                        <p>返回结果类型，值分为：</p>
                        <p>SUCCESS:成功</p>
                        <p>FAILURE:失败</p>
                    </td>
                </tr>
                <tr>
                    <td>requestId</td>
                    <td>String</td>
                    <td>每次请求唯一的字符串</td>
                </tr>
                <tr>
                    <td>errorMessage</td>
                    <td>String</td>
                    <td>错误信息，成功返回值为空字符串</td>
                </tr>
                <tr>
                    <td>libraryId</td>
                    <td>String</td>
                    <td>人脸库ID</td>
                </tr>
                <tr>
                    <td>libraryName</td>
                    <td>String</td>
                    <td>
                        <p>人脸库名称</p>
                    </td>
                </tr>
                <tr>
                    <td>thresholds</td>
                    <td>Float</td>
                    <td>人脸库阈值，精确到小数点后两位</td>
                </tr>
                <tr>
                    <td>tokens</td>
                    <td>Array</td>
                    <td>人脸标识token的数组集合</td>
                </tr>
                </tbody>
            </table>

            <p>请求成功返回示例：</p>
            <pre class="dashed-area">
{
    "state": "SUCCESS",
    "errorMessage": "",
    "requestId": "ed376c8e9-46d7-aee6-ef8cd0335-1558c7",
    "libraryId": "29809738937793F8DF7936C69B84ED23",
    "libraryName": "nameOne",
    "thresholds": 80.23,
    "tokens": [
        "d887d55b-28f5-422b-c537-653e6ee21d25",
        "a9691696-5f9b-b53b-ac36-0b00e9a064fc",
        "4c857a2c-cb6f-4c13-b7ac-86f31a8a1771"
    ]
}</pre>

            <p>请求失败返回示例：</p>
            <pre class="dashed-area">
{
    "state": "FAILURE",
    "errorMessage": "ERROR_LIBRARYID_IS_MUST"
}</pre>
        </article>
        <article class="article-group">
            <h3 class="article-title">错误信息</h3>
            <table class="table table-area error-table">
                <thead>
                <tr>
                    <th>错误信息</th>
                    <th>说明</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>ERROR_LIBRARYID_IS_MUST</td>
                    <td>人脸库ID是必须的</td>
                </tr>
                <tr>
                    <td>ERROR_LIBRARY_ISNOT_FOUND</td>
                    <td>人脸库不存在</td>
                </tr>
                <tr>
                    <td>ERROR_KEY_ISNOT_LEGAL</td>
                    <td>Key不合法</td>
                </tr>
                <tr>
                    <td>ERROR_SECRET_ISNOT_LEGAL</td>
                    <td>Secret不合法</td>
                </tr>
                </tbody>
            </table>
        </article>
    </div>
</template>

<script>
    export default {
        name: "DocFaceLibraryResourceShow"
    }
</script>

<style scoped>

</style>