<template>
    <div id="face-contrast" class="js-article-wrap">
        <ol class="breadcrumb">
            <li><a href="javascript:void(0)">人脸识别</a></li>
            <li class="active">人脸对比</li>
        </ol>
        <article class="article-group">
            <h3 class="article-title">简介</h3>
            <p class="ordinary-text">将两张人脸进行对比，比较他们的相似度。返回对比结果置信度和阈值。</p>
            <p class="ordinary-text">支持传入objectToken进行比较。</p>
            <p class="ordinary-text">图像中检测到多个人脸的情况下，仅对检测到的最大的人脸进行对比。</p>
        </article>
        <article class="article-group">
            <h3 class="article-title">图片要求</h3>
            <p class="ordinary-text">图片格式：JPG(JPEG)，PNG</p>
            <p class="ordinary-text">图片像素尺寸：最小 100*100 像素，最大 4096*4096 像素</p>
            <p class="ordinary-text">图片文件大小：2 MB</p>
            <p class="ordinary-text">最小人脸像素尺寸： 系统能够检测到的人脸框为一个正方形，正方形边长的最小值不低于100 像素</p>
        </article>
        <article class="article-group">
            <h3 class="article-title">调用地址</h3>
            <pre class="dashed-area">/face/v1/compare</pre>
        </article>
        <article class="article-group">
            <h3 class="article-title">调用方式</h3>
            <pre class="dashed-area">POST</pre>
        </article>
        <article class="article-group">
          <h3 class="article-title">请求数据格式</h3>
          <pre class="dashed-area">Content-Type: multipart/form-data</pre>
        </article>
        <h3 class="article-title">请求参数</h3>
        <table class="table table-area">
            <thead>
            <tr>
                <th>是否必需</th>
                <th>参数名</th>
                <th>类型</th>
                <th>说明</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>必须</td>
                <td>appKey</td>
                <td>String</td>
                <td>API key</td>
            </tr>
            <tr>
                <td>必须</td>
                <td>appSecret</td>
                <td>String</td>
                <td>API secret</td>
            </tr>
            <tr>
                <td rowspan="4">必须（四选一）</td>
                <td>objectTokenOne</td>
                <td>String</td>
                <td>第一张人脸标识</td>
            </tr>
            <tr>
                <td>imageUrlOne</td>
                <td>String</td>
                <td>图片的URL</td>
            </tr>
            <tr>
                <td>imageDataOne</td>
                <td>File</td>
                <td>上传的图片二进制文件。需post multipart/form-data方式</td>
            </tr>
            <tr>
                <td>imageBase64One</td>
                <td>String</td>
                <td>Base64编码的二进制图片数据</td>
            </tr>
            <tr>
                <td rowspan="4">必须（四选一）</td>
                <td>objectTokenTwo</td>
                <td>String</td>
                <td>第二张人脸标识</td>
            </tr>
            <tr>
                <td>imageUrlTwo</td>
                <td>String</td>
                <td>图片的URL</td>
            </tr>
            <tr>
                <td>imageDataTwo</td>
                <td>File</td>
                <td>上传的图片二进制文件。需post multipart/form-data方式</td>
            </tr>
            <tr>
                <td>imageBase64Two</td>
                <td>String</td>
                <td>Base64编码的二进制图片数据</td>
            </tr>
            <tr>
                <td>可选（仅供正式API使用）</td>
                <td>objectRectangleOne</td>
                <td>String</td>
                <td>第一张人脸图像按指定的位置检测人脸信息。分别为左上角横坐标，左上角纵坐标，宽度，高度。中间用“,”分割。如：“400,300,200,100”</td>
            </tr>
            <tr>
                <td>可选（仅供正式API使用）</td>
                <td>objectRectangleTwo</td>
                <td>String</td>
                <td>第二张人脸图像按指定的位置检测人脸信息。分别为左上角横坐标，左上角纵坐标，宽度，高度。中间用“,”分割。如：“400,300,200,100”</td>
            </tr>
            </tbody>
        </table>
        <article class="article-group">
            <h3 class="article-title">返回参数</h3>
            <p>数据格式JSON：</p>
            <table class="table table-area">
                <thead>
                <tr>
                    <th>字段</th>
                    <th>类型</th>
                    <th>说明</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>state</td>
                    <td>String</td>
                    <td>
                        <p>返回结果类型，值分为：</p>
                        <p>SUCCESS:成功</p>
                        <p>FAILURE:失败</p>
                    </td>
                </tr>
                <tr>
                    <td>requestId</td>
                    <td>String</td>
                    <td>每次请求唯一的字符串</td>
                </tr>
                <tr>
                    <td>errorMessage</td>
                    <td>String</td>
                    <td>错误信息，成功返回值为空字符串</td>
                </tr>
                <tr>
                    <td>confidence</td>
                    <td>Float</td>
                    <td>对比结果置信度，结果最小为0，最大为100，精确到小数点后三位。数值越大说明是同一个人的可能性越大</td>
                </tr>
                <tr>
                    <td>thresholds</td>
                    <td>Float</td>
                    <td>用于参考的置信度阈值，精确到小数点后两位</td>
                </tr>
                <tr>
                    <td>imageUrlOne</td>
                    <td>String</td>
                    <td>第一张人脸图像url</td>
                </tr>
                <tr>
                    <td>imageUrlTwo</td>
                    <td>String</td>
                    <td>第二张人脸图像url</td>
                </tr>
                <tr>
                    <td>faceOne</td>
                    <td>Array</td>
                    <td>第一张图检测到的人脸数组，以第一张人脸进行对比，未检测到则是空数组 （见下文faces结构）</td>
                </tr>
                <tr>
                    <td>faceTwo</td>
                    <td>Array</td>
                    <td>第二张图检测到的人脸数组，以第一张人脸进行对比，未检测到则是空数组 （见下文faces结构）</td>
                </tr>
                </tbody>
            </table>

            <p>faces数据结构：</p>
            <table class="table table-area">
                <thead>
                <tr>
                    <th>字段</th>
                    <th>类型</th>
                    <th>说明</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>objectToken</td>
                    <td>String</td>
                    <td>人脸唯一</td>
                </tr>
                <tr>
                    <td>faceLocate</td>
                    <td>Object</td>
                    <td>
                        <p>人脸框定位坐标，包括下面属性，每个属性都为int类型：</p>
                        <ul>
                            <li>left ：人脸框左上角横坐标</li>
                            <li>toli：人脸框左上角纵坐标</li>
                            <li>width：人脸框宽度</li>
                            <li>height：人脸框高度</li>
                        </ul>
                    </td>
                </tr>
                </tbody>
            </table>

            <p>请求成功返回示例：</p>
            <pre class="dashed-area">
{
    "state": "SUCCESS",
    "errorMessage": "",
    "confidence": 78.563,
    "thresholds": 16.5,
    "imageUrlOne": "http://host/image/one.jpg",
    "imageUrlTwo": "http://host/image/two.jpg",
    "requestId": "ed376c8e9-46d7-aee6-ef8cd0335-1558c7",
    "faceOne": [
        {
            "faceLocate": {
                "left": 133,
                "top": 215,
                "width": 260,
                "height": 260
         },
        "objectToken": "c0ZDBmZjMwNDBhODMzMzQ4ZDM="
        }
    ],
    "faceTwo": [
        {
            "faceLocate": {
                "left": 133,
                "top": 215,
                "width": 260,
                "height": 260
            },
            "objectToken": "c0ZSDimMwNDBhODMzMzQ4ZDM="
        }
    ]
}</pre>

            <p>请求失败返回示例：</p>
            <pre class="dashed-area">
{
    "state": "FAILURE",
    "errorMessage": "ERROR_IMAGE_IS_MUST"
}</pre>
            <article class="article-group">
                <h3 class="article-title">错误信息</h3>
                <table class="table table-area error-table">
                    <thead>
                    <tr>
                        <th>错误信息</th>
                        <th>说明</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>ERROR_IMAGE_IS_MUST</td>
                        <td>图片信息是必须的</td>
                    </tr>
                    <tr>
                        <td>ERROR_KEY_ISNOT_LEGAL</td>
                        <td>Key不合法</td>
                    </tr>
                    <tr>
                        <td>ERROR_SECRET_ISNOT_LEGAL</td>
                        <td>Secret不合法</td>
                    </tr>
                    <tr>
                        <td>ERROR_SOURCE_FAILED</td>
                        <td>获取资源失败</td>
                    </tr>
                    <tr>
                        <td>ERROR_FACE_FEATURE_FAILED</td>
                        <td>未从图像中提取到人脸特征</td>
                    </tr>
                    </tbody>
                </table>
            </article>
        </article>
    </div>
</template>

<script>
    export default {
        name: "DocFaceContrast"
    }
</script>

<style scoped>

</style>