<template>
  <div id="common-content" class="js-article-wrap">
    <ol class="breadcrumb">
      <li><a href="javascript:void(0)">通用说明</a></li>
    </ol>
    <article class="article-group">
      <h3 class="article-title">请求地址</h3>
      <div class="dashed-area request-area">
        <p class="ordinary-text">https://api.aidoing.com.cn</p>
      </div>
    </article>
    <article class="article-group">
      <h3 class="article-title">支持协议</h3>
      <div class="dashed-area request-area">
        <p class="ordinary-text">HTTPS</p>
      </div>
    </article>
    <article class="article-group">
      <h3 class="article-title">返回数据格式</h3>
      <div class="dashed-area request-area">
        <p class="ordinary-text">JSON</p>
      </div>
    </article>
    <article class="article-group">
      <h3 class="article-title">通用返回数据结构</h3>
      <div class="dashed-area request-area">
        <article  class="article-group">
          <table  class="table table-area">
            <thead >
            <tr >
              <th >字段</th>
              <th >类型</th>
              <th >说明</th>
            </tr>
            </thead>
            <tbody >
            <tr >
              <td >code</td>
              <td >String</td>
              <td >状态码，成功为0, 其余为错误码，具体详情请查看错误码说明章节</td>
            </tr>
            <tr >
              <td >message</td>
              <td >String</td>
              <td >状态信息，返回错误详情</td>
            </tr>
            <tr >
              <td >data</td>
              <td >Object</td>
              <td >单个对象</td>
            </tr>
            <tr >
              <td >collection</td>
              <td >Array</td>
              <td >对象数组，列表查询有数据</td>
            </tr>
            </tbody>
          </table>
          <p >请求成功返回示例：</p>
          <pre  class="dashed-area">{
  "code": 0,
  "message": "成功",
  "data": null,
  "collection": []
}</pre>

        </article>
        <p class="ordinary-text">
        </p>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: "DocGeneralParam"
}
</script>

<style scoped>

</style>